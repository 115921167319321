import PropTypes from 'prop-types';
import React from 'react';

import CurrencyInput from '../../../CurrencyInput/components/CurrencyInput';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const canSubmitForm = (refund, submittingRefund, shouldRestrictRefund) => {
  var atLeastOneTicketSelected = (refund.partial_refunds || []).some((pr) => {
    return (
      pr.quantity
        && pr.quantity === 1
        && pr.ticket.eligible_for_refund
    );
  })

  return (
    refund
      && refund.reason
      && refund.reason.length > 0
      && typeof(refund.amount) === "number"
      && refund.amount >= 0.00
      && atLeastOneTicketSelected
      && !submittingRefund
      && !shouldRestrictRefund
  );
}

const shouldRestrictRefund = (refund, confirm, canBypassRefundRestrictions) => {
  if(canBypassRefundRestrictions){
    return false;
  }

  return (
    refund
      && (
        refund.amount >= 1000.00
          || confirm.calendar_classification === "past"
      )
  );
}

const NewRefund = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  refund,
  refundChanged,
  refundReasons,
  createRefund,
  submittingRefund,
  canBypassRefundRestrictions
}) => (
  <React.Fragment>
    <div className="row m-0">
      <div className="col d-flex align-items-center"
           style={{
             "padding": "25px 25px 22px 25px"
           }}>
        <p className="mb-0"
           style={{"fontSize": "20px"}}>
          <strong>Issue Refund</strong>
        </p>
      </div>
      <div className="col-xs-auto d-flex align-items-center"
           style={{"paddingRight": "25px"}}>
        <a href="#"
           onClick={
             (e) => {
               e.preventDefault();
               refundChanged({});
             }
           }
           style={{"fontSize": "24px"}}
           className="text-muted">
          <i className="fal fa-times"></i>
        </a>
      </div>
    </div>
    <div className="row m-0"
         style={{"borderTop": "2px solid #f5f5f5"}}>
      <div className="col-12"
           style={{"padding": "25px 25px 15px 25px"}}>
        <p className="text-dark"
           style={{"fontSize": "16px", "marginBottom": "5px"}}>
          <strong>Order details</strong>
        </p>
        <table className="table table-sm table-borderless vertical-middle mt-2 small"
               style={{tableLayout: "fixed"}}>
          <colgroup>
            <col span={1} style={{"width": "50%"}} />
            <col span={1} style={{"width": "50%"}} />
          </colgroup>
          <tbody>
            {refund && refund.order ? (
              <React.Fragment>
                <tr>
                  <td className="pt-0 pr-0 pl-0 text-left" style={{"paddingBottom": "7px"}}>
                    Order #:
                  </td>
                  <td className="pt-0 pr-0 pl-0 text-right" style={{"paddingBottom": "7px"}}>
                    {refund.order.id}
                  </td>
                </tr>
                <tr>
                  <td className="pt-0 pr-0 pl-0 text-left" style={{"paddingBottom": "7px"}}>
                    Purchased by:
                  </td>
                  <td className="pt-0 pr-0 pl-0 text-right" style={{"paddingBottom": "7px"}}>
                    {refund.order.purchased_by}
                  </td>
                </tr>
                <tr>
                  <td className="pt-0 pr-0 pl-0 text-left" style={{"paddingBottom": "7px"}}>
                    Purchase date:
                  </td>
                  <td className="pt-0 pr-0 pl-0 text-right" style={{"paddingBottom": "7px"}}>
                    {refund.order.human_purchased_at}
                  </td>
                </tr>
              </React.Fragment>
            ) : null}
            <tr>
              <td className="pt-0 pr-0 pl-0 text-left" style={{"paddingBottom": "7px"}}>
                Original payment:
              </td>
              <td className="pt-0 pr-0 pl-0 text-right" style={{"paddingBottom": "7px"}}>
                {formatter.format(parseFloat(refund.max_amount))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div className="row m-0">
      <div className="col-12"
           style={{"padding": "0px 25px 25px 25px"}}>
        <form onSubmit={
                (e) => {
                  e.preventDefault();

                  if(!canSubmitForm(refund, submittingRefund, shouldRestrictRefund(refund, confirm, canBypassRefundRestrictions))){
                    return false;
                  }

                  createRefund(csrfToken, team, refund.order, confirm, refund);
                }
              }>
          <div className="form-group">
            <label htmlFor="refund-reason"
                   className="text-dark"
                   style={{"fontSize": "16px", "marginBottom": "14px"}}>
              <strong>Refund reason</strong>
            </label>
            <select className="form-control"
                    value={refund.reason || ""}
                    style={{
                      "border": "1px solid #e5e5e5",
                      "borderRadius": "4px",
                      "fontSize": "14px"
                    }}
                    onChange={
                      (e) => {
                        var updated = Object.assign({}, refund, {reason: e.target.value});
                        refundChanged(updated);
                      }
                    }
                    id="refund-reason">
              <option value="" disabled="disabled">
                Select a refund reason
              </option>
              {refundReasons.map((reason, index) =>
                <option key={index} value={reason}>
                  {reason}
                </option>
              )}
            </select>
          </div>
          <div className="form-row">
            <div className="col-12">
              <p className="text-dark"
                 style={{"fontSize": "16px", "marginTop": "17px", "marginBottom": "6px"}}>
                <strong>Specify tickets to refund and amount</strong>
              </p>
              <p className="text-muted mb-0 small">
                Enter the refund amount for each ticket purchased. To enter a partial refund without releasing the ticket, leave the refund quantity 0 and enter the refund amount.
              </p>
            </div>
          </div>
          {refund.partial_refunds && refund.partial_refunds.length > 0 ? (
            <div className="form-row">
              <div className="col-12">
                <table className="table vertical-middle table-sm table-blue-borders"
                       style={{tableLayout: "fixed", "marginTop": "20px"}}>
                  <colgroup>
                    <col span={1} style={{"width": "10%"}} />
                    <col span={1} style={{"width": "30%"}} />
                    <col span={1} style={{"width": "15%"}} />
                    <col span={1} style={{"width": "15%"}} />
                    <col span={1} style={{"width": "20%"}} />
                  </colgroup>
                  <thead>
                    <tr className="border-0">
                      <th className="text-center pl-0 border-bottom-blue">QTY</th>
                      <th className="border-bottom-blue">Tickets</th>
                      <th className="border-bottom-blue text-right">Paid</th>
                      <th className="border-bottom-blue text-right">Refund QTY</th>
                      <th className="pr-0 border-bottom-blue">Refund Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {refund.partial_refunds.map((partialRefund, index) =>
                      <tr key={index} className="border-0">
                        <td className="text-center pl-0 border-bottom-blue">
                          1
                        </td>
                        <td className="border-bottom-blue overflow-ellipsis"
                            title={partialRefund.ticket.product_name}>
                          <p className="mb-0">
                            {partialRefund.ticket.seat_assignment && partialRefund.ticket.seat_assignment.length > 0 ? (
                              <span>{partialRefund.ticket.product_name}: {partialRefund.ticket.seat_assignment}</span>
                            ) : (
                              <span>{partialRefund.ticket.product_name}</span>
                            )}
                          </p>
                          {partialRefund.ticket.transferred_to_name ? (
                            <p className="text-muted mb-0 small">
                              Transferred to {partialRefund.ticket.transferred_to_name}{partialRefund.ticket.human_last_transferred_on ? (" on " + partialRefund.ticket.human_last_transferred_on) : null}
                            </p>
                          ) : null}
                        </td>
                        <td className="border-bottom-blue text-right">
                          {partialRefund.ticket.refund_status === "succeeded" ? (
                            <span className="badge small"
                                  style={{
                                    "backgroundColor": "#654f8f",
                                    "color": "white",
                                    "fontWeight": "normal",
                                    "padding": "7px 12px"
                                  }}>
                              Refunded
                            </span>
                          ) : partialRefund.ticket.refund_status === "ach_processing" ? (
                            <span className="badge small"
                                  style={{
                                    "backgroundColor": "#9d8bbe",
                                    "color": "white",
                                    "fontWeight": "normal",
                                    "padding": "7px 12px"
                                  }}>
                              Refund in Progress
                            </span>
                          ) : partialRefund.ticket.refund_status === "failed" ? (
                            <span className="badge small"
                                  style={{
                                    "backgroundColor": "#ec4426",
                                    "color": "white",
                                    "fontWeight": "normal",
                                    "padding": "7px 12px"
                                  }}>
                              Refund Failed
                            </span>
                          ) : partialRefund.ticket.disputed_at ? (
                            <span className="badge small"
                                  style={{
                                    "backgroundColor": "black",
                                    "color": "white",
                                    "fontWeight": "normal",
                                    "padding": "7px 12px"
                                  }}>
                              Disputed
                            </span>
                          ) : (
                            formatter.format(parseFloat(partialRefund.max_amount))
                          )}
                        </td>
                        <td className="border-bottom-blue text-right">
                          <select className="form-control d-inline"
                                  value={partialRefund.quantity || "0"}
                                  disabled={!partialRefund.ticket.eligible_for_refund}
                                  style={{
                                    "border": "1px solid #e5e5e5",
                                    "borderRadius": "4px",
                                    "height": "40px",
                                    "fontSize": "14px",
                                    "maxWidth": "88px"
                                  }}
                                  onChange={
                                    (e) => {
                                      var quantity = parseInt(e.target.value);
                                      var maxAmount = parseFloat(partialRefund.max_amount);

                                      var partialRefunds = [...refund.partial_refunds].map((pf, i) => {
                                        if(i === index){
                                          return Object.assign({}, partialRefund, {
                                            quantity: quantity,
                                            amount: (quantity === 0 ? "" : maxAmount)
                                          });
                                        } else {
                                          return pf;
                                        }
                                      });

                                      var totalAmount = partialRefunds.reduce((sum, pf) => {
                                        return (sum + (pf.amount || 0));
                                      }, 0);

                                      var maxTotal = parseFloat(refund.max_amount);
                                      var correctedTotal = (
                                        totalAmount > maxTotal ? maxTotal : totalAmount
                                      );

                                      var updated = Object.assign({}, refund, {
                                        partial_refunds: partialRefunds,
                                        amount: correctedTotal
                                      });

                                      refundChanged(updated);
                                    }
                                  }>
                            <option value="0">
                              0
                            </option>
                            <option value="1">
                              1
                            </option>
                          </select>
                        </td>
                        <td className="pr-0 border-bottom-blue">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text text-muted"
                                    style={{
                                      "borderLeft": "1px solid #e6e6e6",
                                      "borderTop": "1px solid #e6e6e6",
                                      "borderBottom": "1px solid #e6e6e6",
                                      "borderRight": "none",
                                      "borderTopLeftRadius": "4px",
                                      "borderBottomLeftRadius": "4px",
                                      "fontSize": "14px"
                                    }}>
                                <i className="fas fa-dollar-sign"></i>
                              </span>
                            </div>
                            <CurrencyInput className="form-control text-right"
                                           maskOptions={{
                                             prefix: "",
                                             integerLimit: null
                                           }}
                                           style={{
                                             "borderRight": "1px solid #e6e6e6",
                                             "borderTop": "1px solid #e6e6e6",
                                             "borderBottom": "1px solid #e6e6e6",
                                             "borderLeft": "none",
                                             "borderRadius": "4px",
                                             "fontSize": "14px",
                                             "height": "40px"
                                           }}
                                           placeholder="0.00"
                                           value={(partialRefund.amount ? partialRefund.amount.toFixed(2) : "")}
                                           disabled={true}
                                           onChange={
                                             (e) => {
                                               // var updated = Object.assign({}, ticketTypeForForm, {price_two_decimals: e.target.value});
                                               // ticketTypeForFormChanged(updated);
                                             }
                                           } />
                          </div>
                        </td>
                      </tr>
                    )}
                    <tr className="border-0">
                      <td className="text-right pl-0" colSpan={4}>
                        <strong>Total Refund</strong>
                      </td>
                      <td className="pr-0">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text text-muted"
                                  style={{
                                    "borderLeft": "1px solid #e6e6e6",
                                    "borderTop": "1px solid #e6e6e6",
                                    "borderBottom": "1px solid #e6e6e6",
                                    "borderRight": "none",
                                    "fontSize": "14px"
                                  }}>
                              <i className="fas fa-dollar-sign"></i>
                            </span>
                          </div>
                          <CurrencyInput className="form-control text-right"
                                         maskOptions={{
                                           prefix: "",
                                           integerLimit: null
                                         }}
                                         style={{
                                           "borderRight": "1px solid #e6e6e6",
                                           "borderTop": "1px solid #e6e6e6",
                                           "borderBottom": "1px solid #e6e6e6",
                                           "borderLeft": "none",
                                           "fontSize": "14px"
                                         }}
                                         placeholder="0.00"
                                         value={(refund.amount ? refund.amount.toFixed(2) : "")}
                                         disabled={true}
                                         onChange={
                                           (e) => {
                                             // var updated = Object.assign({}, ticketTypeForForm, {price_two_decimals: e.target.value});
                                             // ticketTypeForFormChanged(updated);
                                           }
                                         } />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
          <div className="form-row"
               style={{"marginTop": "37px"}}>
            {shouldRestrictRefund(refund, confirm, canBypassRefundRestrictions) ? (
              <div className="col-12 text-right">
                <p className='text-danger'>
                  <i className="fas fa-exclamation-circle mr-2"></i>
                  Contact <a className='text-danger' href={"mailto:support@opendate.io"}>support@opendate.io</a> to process this refund.
                </p>
              </div>
            ) : null}
            <div className="col-12 text-right">
              {!submittingRefund ? (
                <a href="#"
                   onClick={
                     (e) => {
                       e.preventDefault();
                       refundChanged({});
                     }
                   }
                   className="mr-3">
                  <strong>Cancel</strong>
                </a>
              ) : null}
              <button type="submit"
                      disabled={!canSubmitForm(refund, submittingRefund, shouldRestrictRefund(refund, confirm, canBypassRefundRestrictions))}
                      className="btn btn-primary btn-sm rounded">
                {submittingRefund ? (
                  <React.Fragment>
                    <img src="/uploading-loading.gif"
                         className="mr-1"
                         style={{width: "16px"}} />
                    <strong>Continue</strong>
                  </React.Fragment>
                ) : (
                  <strong>Continue</strong>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </React.Fragment>
);

NewRefund.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  refund: PropTypes.object,
  refundChanged: PropTypes.func.isRequired,
  refundReasons: PropTypes.array,
  createRefund: PropTypes.func.isRequired,
  submittingRefund: PropTypes.bool,
  canBypassRefundRestrictions: PropTypes.bool
};

export default NewRefund;
