import React, { createContext, useState, useContext, useEffect } from "react";
import { ToastContainer } from "react-toastify";

import axios from "axios";

import MarketingSettingsIndex from "./MarketingSettingsIndex";
import { toastSuccess, toastError } from "../../../shared/toastHelper";

const MarketingSettingsContext = createContext();

export function useMarketingSettingsContext() {
  return useContext(MarketingSettingsContext);
}

const MarketingSettingsProvider = (props) => {
  const venueOwnershipIdParam = new URLSearchParams(window.location.search).get("venue_ownership_id");

  const { csrfToken, team, lastTouchedVenueOwnership } = props;

  const [venueOwnerships, setVenueOwnerships] = useState(props.venueOwnerships);
  const [venueOwnership, setVenueOwnership] = useState(() => {
    if (!!venueOwnershipIdParam) {
      return venueOwnerships.find((ownership) => ownership.id === parseInt(venueOwnershipIdParam));
    } else {
      return lastTouchedVenueOwnership
    }}
  );
  const [sendDomains, setSendDomains] = useState(venueOwnership.send_domains);
  const [formState, setFormState] = useState({});
  const [logoThumbUrl, setLogoThumbUrl] = useState(venueOwnership.logo_thumb_url);
  const [logoFile, setLogoFile] = useState(null);
  const [testModalOpen, setTestModalOpen] = useState(false);

  const extractFormState = (venueOwnership) => {
    setFormState({
      time_zone: venueOwnership.time_zone,
      facebook_url: venueOwnership.facebook_url || "",
      twitter_url: venueOwnership.twitter_url || "",
      instagram_url: venueOwnership.instagram_url || "",
      linkedin_url: venueOwnership.linkedin_url || "",
      primary_color_hex: venueOwnership.primary_color_hex || "",
      secondary_color_hex: venueOwnership.secondary_color_hex || "",
      font_family: venueOwnership.font_family || "",
    });
  };

  useEffect(() => {
    extractFormState(venueOwnership);
    setSendDomains(venueOwnership.send_domains);
    setLogoThumbUrl(venueOwnership.logo_thumb_url);
  }, [venueOwnership]);

  const sendDomainModified = (sendDomain) => {
    const newSendDomains = sendDomains.map((domain) => {
      return domain.id === sendDomain.id ? sendDomain : domain
    });

    setSendDomains(newSendDomains);
    setVenueOwnerships(venueOwnerships.map((ownership) => {
      return ownership.id === venueOwnership.id ? { ...venueOwnership, send_domains: newSendDomains } : ownership;
    }));
  };

  const sendDomainCreated = (venueOwnership, sendDomain) => {
    const newSendDomains = venueOwnership.send_domains.concat([sendDomain]);
    setSendDomains(newSendDomains);
    setVenueOwnerships(venueOwnerships.map((ownership) => {
      return ownership.id === venueOwnership.id ? { ...venueOwnership, send_domains: newSendDomains } : ownership;
    }));
  };

  const sendDomainAdded = () => {
    setSendDomains([{
      domain: "",
      from_address: "",
      verified: false,
      send_domain_dns_records: [],
    }])
  }

  const updateMarketingSettings = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    const formData = new FormData();
    Object.keys(formState).forEach(key => {
      formData.append(`venue_ownership[${key}]`, formState[key]);
    });

    if (logoFile) {
      formData.append("venue_ownership[logo]", logoFile);
    }

    if (venueOwnership.logo_thumb_url && !logoFile) {
      formData.append("venue_ownership[destroy_logo]", "true");
    }

    axios.put(`/teams/${team.id}/venue_ownerships/${venueOwnership.id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
      .then(() => {
        toastSuccess("Marketing settings updated successfully");
      })
      .catch((_error) => {
        toastError("Could not update marketing settings, please try again");
      });

    sendDomains.forEach((sendDomain) => {
      updateSendDomain(sendDomain);
    });
  };

  const updateSendDomain = (sendDomain) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.put(`/teams/${team.id}/venue_ownerships/${venueOwnership.id}/send_domains/${sendDomain.id}`, {
      send_domain: {
        from_address: sendDomain.from_address,
        name: sendDomain.name,
      }
    })
      .then(({ data }) => {
        sendDomainModified(data);
        toastSuccess("Send domain updated successfully");
      })
      .catch(({ response }) => {
        if (response && response.data) {
          const { errors } = response.data;

          toastError(errors.join(", "));
        } else {
          toastError("Could not update send domain");
        }
      });
  };

  const value = {
    ...props,
    venueOwnerships,
    setVenueOwnerships,
    venueOwnership,
    setVenueOwnership,
    sendDomains,
    setSendDomains,
    formState,
    setFormState,
    sendDomainCreated,
    sendDomainModified,
    sendDomainAdded,
    updateMarketingSettings,
    logoFile,
    setLogoFile,
    logoThumbUrl,
    setLogoThumbUrl,
    testModalOpen,
    setTestModalOpen,
    updateSendDomain,
  };

  return (
    <MarketingSettingsContext.Provider value={value}>
      {props.children}
    </MarketingSettingsContext.Provider>
  );
};

const MarketingSettingsIndexContainer = (props) => (
  <MarketingSettingsProvider {...props}>
    <ToastContainer />
    <MarketingSettingsIndex />
  </MarketingSettingsProvider>
);

export default MarketingSettingsIndexContainer;
