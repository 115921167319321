import React from "react";

import Tippy from "@tippyjs/react";
import { useSegmentNewContext } from "./SegmentNewContainer";

const titleStyle = {
  color: "#333333",
  fontSize: 16,
  fontWeight: 600,
};

const backButtonStyle = {
  padding: "12px 12px",
  color: "#1982C4",
  fontWeight: "bold",
};

const SegmentNew = () => {
  const {
    team,
    name,
    setName,
    segmentType,
    setSegmentType,
    createSegment,
  } = useSegmentNewContext();

  return (
    <div>
      <div className="mt-3 mb-2">
        <div>
          <div style={titleStyle}>
            New Segment
          </div>
        </div>
      </div>
      <div
        style={{ backgroundColor: "#fff" }}
        className="rounded border p-4"
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group">
            <div style={{ width: "400px" }}>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mt-3">
              <label htmlFor="broadcastType">Choose a broadcast type</label>
              <div className="d-flex flex-wrap">
                <button
                  type="button"
                  className="d-flex align-items-center mr-4 mb-4 p-3"
                  style={{
                    width: "300px",
                    minHeight: "96px",
                    backgroundColor: segmentType === "Segments::Manual" ? "#eef7fd" : "#fff",
                    border: segmentType === "Segments::Manual" ? "2px solid #1982C4" : "2px solid #ccc",
                    borderRadius: "10px",
                  }}
                  onClick={() => setSegmentType("Segments::Manual")}
                >
                  <div className="ml-3 text-left">
                    <p className="mb-0" style={{ fontSize: "16px" }}>
                      <strong>
                        Manual
                      </strong>
                    </p>
                    <p className="mb-0" style={{ fontSize: "12px" }}>
                      Upload a CSV.
                    </p>
                  </div>
                </button>
                <Tippy content="Coming soon!">
                  <span className="d-block mr-4 mb-4">
                    <button
                      type="button"
                      disabled
                      className="d-flex align-items-center p-3 text-muted"
                      style={{
                        width: "300px",
                        minHeight: "96px",
                        backgroundColor: segmentType === "data_driven" ? "#eef7fd" : "#fff",
                        border: segmentType === "data_driven" ? "2px solid #1982C4" : "2px solid #ccc",
                        borderRadius: "10px",
                      }}
                      onClick={() => setSegmentType("data_driven")}
                    >
                      <div className="ml-3 text-left">
                        <p className="mb-0" style={{ fontSize: "16px" }}>
                          <strong>
                            Data driven
                          </strong>
                        </p>
                        <p className="mb-0" style={{ fontSize: "12px" }}>
                          Build from attributes about events or fans.
                        </p>
                      </div>
                    </button>
                  </span>
                </Tippy>
              </div>
            </div>
            <div
              className="d-flex justify-content-end"
              style={{ marginTop: "250px" }}
            >
              <a href={`/teams/${team.id}/segments`} style={backButtonStyle}>
                Back
              </a>
              <button
                type="button"
                className="ml-2 btn btn-primary"
                onClick={e => {
                  e.preventDefault();

                  createSegment({
                    segment: {
                      name: name,
                      type: segmentType
                    }
                  });
                }}
              >
                Create
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SegmentNew;
