import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";

import ContainerlessTableViewApp from "../../TableView/startup/ContainerlessTableViewApp";
import PeopleCard from "./PeopleCard";
import CSVImporter from "../../CSVImporter/CSVImporter";
import { toastSuccess, toastError } from "../../../shared/toastHelper";

const containerStyle = {
  backgroundColor: "white",
  borderRadius: "10px",
  boxShadow: "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  display: "flex",
  flexDirection: "column",
  marginBottom: "20px",
  marginTop: "20px",
  padding: "20px",
};

const headerContainerStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}

const headerGroupStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: "10px",
};

const importButtonStyle = {
  backgroundColor: "#1982C4",
  border: "1px solid #1982C4",
  borderRadius: "5px",
  color: "white",
  fontSize: "12px",
  fontWeight: "bold",
  height: "40px",
  width: "125px",
};

const exportButtonStyle = {
  ...importButtonStyle,
  backgroundColor: "white",
  color: "#1982C4",
};

const eventTitleStyle = {
  fontSize: "16px",
  fontWeight: "bold",
};

const headerStyle = {
  color: "#666",
  paddingBottom: "10px",
};

const pageContainerStyle = {
  paddingInline: "30px",
};

const inlineFormStyle = {
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  gap: 5,
};

const inputStyle = {
  border: "none",
  background: "transparent",
  fontSize: "inherit",
  fontWeight: "inherit",
  width: 400,
};

const submitButtonStyle = {
  border: "none",
  backgroundColor: "transparent",
};

const iconStyle = {
  cursor: "pointer",
  color: "#333333",
  opacity: 0.8,
};

const SegmentDashboard = ({
  csrfToken,
  team,
  segment,
  data_table_props
}) => {
  const [name, setName] = React.useState(segment.name);
  const [isEditingName, setIsEditingName] = useState(false);

  const updateSegment = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.put(`/teams/${team.id}/segments/${segment.id}`, { segment: { name } })
      .then(() => {
        setIsEditingName(false);
        toastSuccess("Segment updated successfully");
      })
      .catch(() => toastError("Could not update segment, please try again"));
  };

  return (
    <div>
      <ToastContainer />
      <div style={headerStyle}>
        Segments / {name}
      </div>
      <div style={pageContainerStyle}>
        <div style={headerContainerStyle}>
          <div style={headerGroupStyle}>
            <div style={eventTitleStyle}>
              {segment.type === "Segments::Manual" ? (
                isEditingName ? (
                  <form style={inlineFormStyle} onSubmit={(e) => { e.preventDefault(); updateSegment(false); }}>
                    <>
                      <input
                        autoFocus
                        style={inputStyle}
                        value={name}
                        disabled={!isEditingName}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <>
                        <button type="submit" style={submitButtonStyle}>
                          <i className="far fa-check" style={iconStyle} />
                        </button>
                        <i className="far fa-times" style={iconStyle} onClick={() => { setName(segment.subject); setIsEditingName(false); }}></i>
                      </>
                    </>
                  </form>
                ) : (
                  <div style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                  }}>
                    <div>{name}</div>
                    <i className="far fa-pencil" style={iconStyle} onClick={() => setIsEditingName(true)} />
                  </div>
                )
              ) : name }
            </div>
            {segment.calendar_event ? (
              <a href={`/teams/${team.id}/confirms/${segment.calendar_event.id}`}>
                Go to Event Page →
              </a>
            ) : null}
          </div>
          <div style={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
          }}>
            {segment.type === "Segments::Manual" ? (
              <CSVImporter
                csrfToken={csrfToken}
                team={team}
                importerKey={"ef329644-a20c-4084-bf4a-79d8ca44bca6"}
                type={"CSVImportRows::ManualSegmentFan"}
                additionalParams={{ segment_id: segment.id }}
                onClose={() => window.location.reload() }
                renderAnchor={(openModal) => (
                  <button
                    style={importButtonStyle}
                    onClick={(e) => {
                      e.preventDefault();
                      openModal();
                    }}
                  >
                    Import
                  </button>
                )}
              />
            ) : null}
            <div style={headerGroupStyle}>
              <button style={exportButtonStyle}
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = `/teams/${team.id}/segments/${segment.id}/targeted_fans.csv`;
                }}
              >
                Export
              </button>
            </div>
          </div>
        </div>
        <div style={containerStyle}>
          <PeopleCard segment={segment} />
          <ContainerlessTableViewApp
            {...data_table_props}
          />
        </div>
      </div>
    </div>
  );
};

export default SegmentDashboard;
