import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import HoldPositionPicker from './../../EventForm/components/HoldPositionPicker';

import { toast } from 'react-toastify';
import Tippy from '@tippyjs/react';
import Popup from "reactjs-popup";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
var moment = require('moment');

const axios = require('axios').default;

import {
  offsetDateForBrowser
} from '../../../shared/timeZoneLogic';

const modalStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "528px",
  "padding": "0px"
}

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "transparent",
    zIndex: "999"
  }),
  menuPortal: base => ({ ...base, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: "9999 !important" })
};

const formatDate = (time) => {
  const datetime = offsetDateForBrowser(new Date(time));

  return moment(datetime).format("MM/DD/YY");
}

const formatTime = (time) => {
  const datetime = offsetDateForBrowser(new Date(time));

  return datetime.toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric"
  });
}

const debouncedFetchEventTemplates = _.debounce((team, inputValue, callback) => {
  new Promise(resolve => {
    axios.get(`/teams/${team.id}/event_templates`).then(function (response) {
      var newOptions = response.data.map(function(data){
        return { value: data.id, label: data.name };
      });

      callback(newOptions);
    })
  });
}, 250);

const EventDetails = ({
  csrfToken,
  team,
  confirm,
  confirmSaved,
  updateConfirm,
  buyers,
  buyersChanged,
  promoters,
  promotersChanged,
  eventDetails,
  eventDetailsChanged,
  fetchBuyers,
  createBuyer,
  fetchPromoters,
  createPromoter,
  currentUser,
  financeItemsChanged,
  fetchEventDetails,
  holdGroupDates,
  holdGroupHoldPositionUpdated,
  holdGroupHoldRemoved,
  holdGroupCalendarDateClicked,
  fetchHoldAvailability
}) => {
  const [makeUnavailable, setMakeUnavailable] = useState(false);
  const debouncedFetchBuyers = _.debounce((team, inputValue, callback) => {
    fetchBuyers(team, inputValue, callback);
  }, 250);

  const debouncedFetchPromoters = _.debounce((team, inputValue, callback) => {
    fetchPromoters(team, inputValue, callback);
  }, 250);

  const holdPositionsInOrder = confirm.hold_positions.sort((a, b) => {
    return new Date(a.start_time) - new Date(b.start_time);
  });

  return(
    <React.Fragment>
      <div className="pt-2 pb-1">
        {confirm.type === "Confirm" ? (
          <Tippy content="Date and Time">
            <span className="mb-2 mr-2 badge badge-confirm-metadata">
              <i className="pr-2 far fa-calendar"></i>
              {formatTime(confirm.start_time)}
            </span>
          </Tippy>
        ) : (
          <Tippy content={
                    holdPositionsInOrder
                      .map((hp, index) =>
                        <div key={index}>
                          {`H${hp.position}`} - {formatDate(hp.start_time)}
                        </div>
                      )
                  }>
            <span className="mb-2 mr-2 badge badge-confirm-metadata">
              <i className="pr-2 far fa-calendar"></i>
              {holdPositionsInOrder.length === 1 ? (
                <span>
                  <strong className='mr-1'>
                    1 Hold
                  </strong>
                  {formatDate(holdPositionsInOrder[0].start_time)}
                </span>
              ) : (
                <span>
                  <strong className='mr-1'>
                    {holdPositionsInOrder.length} Holds
                  </strong>
                  {formatDate(holdPositionsInOrder[0].start_time)} - {formatDate(holdPositionsInOrder[holdPositionsInOrder.length - 1].start_time)}
                </span>
              )}
            </span>
          </Tippy>
        )}
        <Tippy content="Venue">
          <span className="mb-2 mr-2 badge badge-confirm-metadata">
            <i className="pr-2 far fa-map-marker"></i>
            {confirm.venue.name}
          </span>
        </Tippy>
        {!confirm.buyer ? null :
          <Tippy content="Buyer">
            <span className="mb-2 mr-2 badge badge-confirm-metadata">
              <i className="pr-2 far fa-user-magnifying-glass"></i>
              {confirm.buyer.name}
            </span>
          </Tippy>
        }
        {!confirm.promoter ? null :
          <Tippy content="Promoter">
            <span className="mb-2 mr-2 badge badge-confirm-metadata">
              <i className="pr-2 far fa-bullhorn"></i>
              {confirm.promoter.name}
            </span>
          </Tippy>
        }
        {!confirm.event_template ? null :
          <Tippy content="Event Template">
            <span className="mb-2 mr-2 badge badge-confirm-metadata">
              <i className="pr-2 fas fa-grid"></i>
              {confirm.event_template.name}
            </span>
          </Tippy>
        }
        {currentUser.can_update_calendar_event ? (
          <a className="d-inline-block mb-2 text-primary"
              style={{"cursor": "pointer", "fontSize": "12px"}}
              onClick={(e) => {
                e.preventDefault()
                fetchEventDetails(team, confirm);
              }}>
            <i className="px-1 fas fa-pencil"></i>
            Edit Details
          </a>
        ) : null}
      </div>
      <Popup
        open={Object.keys(eventDetails).length > 0}
        contentStyle={modalStyle}
        closeOnDocumentClick={false}
        className={"popup-modal"}
        onClose={() => eventDetailsChanged({})} >
        <div className="popup-modal-container">
          <div className="border-bottom p-4 d-flex align-items-center justify-content-between"
               style={{"borderColor": "#ccc"}}>
            <h5 className="m-0">
              <strong>Event Details</strong>
            </h5>
            <a href="#"
               className="mr-1"
               style={{"color": "#999", "fontSize": "20px"}}
               onClick={(e) => {
                 e.preventDefault();
                 eventDetailsChanged({});
               }}>
              <i className="far fa-times"></i>
            </a>
          </div>
          <div className="p-4">
            <div className="mb-3 d-flex flex-column">
              <label htmlFor="edit-event-title"
                    className="mb-1 text-muted">
                Event Template
                <span className="text-optional pl-2">
                  (optional)
                </span>
              </label>
              <AsyncSelect
                cacheOptions
                isClearable={true}
                styles={selectStyles}
                menuPortalTarget={document.querySelector('body')}
                defaultOptions
                id="edit-event-template"
                value={eventDetails.event_template ? eventDetails.event_template : null}
                loadOptions={(inputValue, callback) => debouncedFetchEventTemplates(team, inputValue, callback)}
                onChange={(option) => {
                  eventDetailsChanged({
                    ...eventDetails,
                    event_template: option,
                    event_template_id: option ? option.value : null
                  })
                }}
              />
            </div>
            <div className="mb-3 d-flex flex-column">
              <label htmlFor="edit-event-title"
                     className="mb-1 text-muted">
                Event Name
              </label>
              <input type="text"
                     id="edit-event-title"
                     name="edit-event-title"
                     className="form-control"
                     style={{"fontSize": "14px", "borderWidth": "1px", "borderRadius": "4px"}}
                     value={eventDetails.title || ""}
                     onChange={(e) => eventDetailsChanged({...eventDetails, title: e.target.value})} />
            </div>
            <div className="mb-3 d-flex flex-column">
              <label htmlFor="edit-event-promoter"
                     className="mb-1 text-muted">
                Promoter
              </label>
              <AsyncSelect
                placeholder={"Select or create a promoter"}
                styles={selectStyles}
                menuPortalTarget={document.querySelector('body')}
                isClearable={true}
                defaultOptions={promoters}
                id="edit-event-promoter"
                value={promoters.find(promoter => promoter.value === eventDetails.promoter)}
                loadOptions={(inputValue, callback) => debouncedFetchPromoters(team, inputValue, callback)}
                onChange={
                  (option) => {
                    if(option === null) {
                      eventDetailsChanged({...eventDetails, promoter: null});
                    } else if(promoters.find(promoter => promoter.value === option.value)) {
                      eventDetailsChanged({...eventDetails, promoter: option.value});
                    } else {
                      createPromoter(team, option.name,
                        (newPromoter) => { // Success callback
                          var newOption = { label: newPromoter.name, value: newPromoter.id };
                          promotersChanged([...promoters, newOption]);
                          eventDetailsChanged({...eventDetails, promoter: newOption.value});
                          toast.success(`Promoter "${newPromoter.name}" was created`, {
                            position: toast.POSITION.TOP_CENTER,
                            draggable: false,
                            closeOnClick: false,
                            autoClose: 5000,
                            hideProgressBar: true
                          });
                        },
                        (error) => { // Error callback
                          eventDetailsChanged({...eventDetails, promoter: null});
                          toast.error(`Could not create promoter "${option.name}"`, {
                            position: toast.POSITION.TOP_CENTER,
                            draggable: false,
                            closeOnClick: false,
                            autoClose: 5000,
                            hideProgressBar: true
                          });
                        });
                    }
                  }
                }
              />
            </div>
            <div className="mb-4 d-flex flex-column">
              <label htmlFor="edit-event-buyer"
                     className="mb-1 text-muted">
                Buyer
              </label>
              <AsyncSelect
                placeholder={"Select or create a buyer"}
                styles={selectStyles}
                menuPortalTarget={document.querySelector('body')}
                isClearable={true}
                defaultOptions={buyers}
                id="edit-event-buyer"
                value={buyers.find((buyer) => buyer.value === eventDetails.buyer)}
                loadOptions={(inputValue, callback) => debouncedFetchBuyers(team, inputValue, callback)}
                onChange={
                  (option) => {
                    if(option === null) {
                      eventDetailsChanged({...eventDetails, buyer: null});
                    } else if(buyers.map(buyer => buyer.label).includes(option.label)) {
                      eventDetailsChanged({...eventDetails, buyer: option.value});
                    } else {
                      createBuyer(team, option.name,
                        (newBuyer) => { // Success callback
                          var newOption = { label: newBuyer.name, value: newBuyer.id };
                          buyersChanged([...buyers, newOption]);
                          eventDetailsChanged({...eventDetails, buyer: newOption.value});
                          toast.success(`Company "${newBuyer.name}" was created`, {
                            position: toast.POSITION.TOP_CENTER,
                            draggable: false,
                            closeOnClick: false,
                            autoClose: 5000,
                            hideProgressBar: true
                          });
                        },
                        (error) => { // Error callback
                          eventDetailsChanged({...eventDetails, buyer: null});
                          toast.error(`Could not create company "${option.name}"`, {
                            position: toast.POSITION.TOP_CENTER,
                            draggable: false,
                            closeOnClick: false,
                            autoClose: 5000,
                            hideProgressBar: true
                          });
                        });
                    }
                  }
                }
              />
            </div>
            {confirm.type === "Hold" ? (
              <HoldPositionPicker csrfToken={csrfToken}
                                  team={team}
                                  venueSelected={confirm.venue}
                                  holdGroup={eventDetails.holdGroup || {}}
                                  holdGroupCalendarDateClicked={holdGroupCalendarDateClicked}
                                  holdGroupDates={holdGroupDates}
                                  makeUnavailable={makeUnavailable}
                                  setMakeUnavailable={setMakeUnavailable}
                                  holdGroupHoldPositionUpdated={holdGroupHoldPositionUpdated}
                                  holdGroupHoldRemoved={holdGroupHoldRemoved}
                                  fetchHoldAvailability={fetchHoldAvailability}
                                  calendarStartDate={
                                    offsetDateForBrowser(new Date(holdPositionsInOrder[0].start_time))
                                  } />
            ) : null}
            <div className="mb-3 d-flex flex-column">
              <label htmlFor="edit-event-ticket-forecast"
                     className="mb-1 text-muted">
                Ticket Forecast
              </label>
              <input type="number"
                     id="edit-event-ticket-forecast"
                     name="edit-event-ticket-forecast"
                     className="form-control"
                     style={{"fontSize": "14px", "borderWidth": "1px", "borderRadius": "4px"}}
                     value={eventDetails.ticket_forecast || "0"}
                     onChange={(e) => eventDetailsChanged({...eventDetails, ticket_forecast: e.target.value})} />
            </div>
            <div>
              <a
                href="#"
                className="w-100 btn btn-primary font-weight-bold"
                onClick={(e) => {
                  e.preventDefault();

                  var eventDetailsCopy = {...eventDetails};

                  if(makeUnavailable && confirm.type === "Hold" && eventDetailsCopy && eventDetailsCopy.holdGroup){
                    var filteredHoldGroupEntries = eventDetailsCopy.holdGroup.hold_group_entries.filter((holdGroupEntry) => {
                      var firstHoldAvailability = (holdGroupEntry.hold.hold_availability || [])[0];
                      return firstHoldAvailability && !firstHoldAvailability.is_confirm;
                    });

                    var filteredHoldGroup = {...eventDetailsCopy.holdGroup, hold_group_entries: filteredHoldGroupEntries};
                    eventDetailsCopy = {...eventDetailsCopy, holdGroup: filteredHoldGroup};
                  }

                  updateConfirm(csrfToken, team, confirm, eventDetailsCopy, ((savedConfirm) => {
                    eventDetailsChanged({});

                    if(confirmSaved !== undefined) {
                      confirmSaved(savedConfirm);
                      financeItemsChanged(savedConfirm.finance_items);
                    }

                    toast.success("Event Details updated", {
                      position: toast.POSITION.TOP_CENTER,
                      draggable: false,
                      closeOnClick: false,
                      autoClose: 5000,
                      hideProgressBar: true
                    });
                  }));
                }}>
                Save
              </a>
            </div>
          </div>
        </div>
      </Popup>
    </React.Fragment>
  );
};

EventDetails.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  confirmSaved: PropTypes.func.isRequired,
  updateConfirm: PropTypes.func.isRequired,
  buyers: PropTypes.array,
  buyersChanged: PropTypes.func.isRequired,
  promoters: PropTypes.array,
  promotersChanged: PropTypes.func.isRequired,
  eventDetails: PropTypes.object,
  eventDetailsChanged: PropTypes.func.isRequired,
  fetchBuyers: PropTypes.func.isRequired,
  createBuyer: PropTypes.func.isRequired,
  fetchPromoters: PropTypes.func.isRequired,
  createPromoter: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  financeItemsChanged: PropTypes.func.isRequired,
  fetchEventDetails: PropTypes.func.isRequired,
  holdGroupDates: PropTypes.array,
  holdGroupHoldPositionUpdated: PropTypes.func.isRequired,
  holdGroupHoldRemoved: PropTypes.func.isRequired,
  holdGroupCalendarDateClicked: PropTypes.func.isRequired,
  fetchHoldAvailability: PropTypes.func.isRequired
};

export default EventDetails;
