/* eslint-disable import/prefer-default-export */

import {
  CONFIRM_CHANGED,
  ACTIVE_MENU_ITEM_CHANGED,
  ORDER_TYPE_CHANGED,
  TICKET_TYPES_CHANGED,
  ADD_ONS_CHANGED,
  TICKET_RESERVATION_CHANGED,
  IS_PLACING_ORDER_CHANGED,
  CHECKOUT_TIME_PERCENTAGE_REMAINING_CHANGED,
  TIME_LIMIT_REACHED_CHANGED,
  CANCEL_CHECKOUT,
  CUSTOM_FIELD_VALUE_CHANGED,
  SEATS_IO_CHART_CHANGED,
  SEATS_IO_SELECTED_OBJECTS_CHANGED
} from '../constants/confirmAttendeesConstants';

import {
  customFieldValueToParams
} from './../../CustomFieldValues/actions/customFieldValuesActionCreators';

const axios = require('axios').default;
import { toast } from 'react-toastify';

export const confirmChanged = (confirm) => ({
  type: CONFIRM_CHANGED,
  confirm
});

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

export const activeMenuItemChanged = (activeMenuItem) => ({
  type: ACTIVE_MENU_ITEM_CHANGED,
  activeMenuItem
});

export const orderTypeChanged = (orderType) => ({
  type: ORDER_TYPE_CHANGED,
  orderType
});

export const ticketTypesChanged = (ticketTypes) => ({
  type: TICKET_TYPES_CHANGED,
  ticketTypes
});

export const addOnsChanged = (addOns) => ({
  type: ADD_ONS_CHANGED,
  addOns
});

export const ticketReservationChanged = (ticketReservation) => ({
  type: TICKET_RESERVATION_CHANGED,
  ticketReservation
});

export const buildTicketReservation = (dispatch, csrfToken, team, confirm, orderType, ticketTypes, addOns) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var orderItems = ticketTypes.filter((tt) =>
      tt.quantity && tt.quantity > 0
    );

    var addOnOrderItems = addOns.filter((ao) =>
      ao.quantity && ao.quantity > 0
    );

    if(orderItems.length === 0 && addOnOrderItems.length === 0){
      return dispatch(ticketReservationChanged({}));
    }

    var complimentary = ["Complimentary", "No Payment Necessary"].includes(orderType);

    return axios.post("/teams/" + team.id + "/manual_ticket_reservations/build", {
        ticket_reservation: {
          calendar_event_id: confirm.id,
          complimentary: complimentary,
          manual_type: orderType,
          order_items_attributes: orderItems.map((item) => {
            return {
              ticket_type_id: item.id,
              quantity: item.quantity,
              face_value: parseFloatFromString(item.face_value),
              seatsio_objects: item.seatsIOObjects
            };
          }),
          add_on_order_items_attributes: addOnOrderItems.map((item) => {
            return {
              add_on_id: item.id,
              quantity: item.quantity,
              face_value: parseFloatFromString(item.face_value),
              seatsio_objects: item.seatsIOObjects
            };
          })
        }
      })
      .then(({ data }) => {
        dispatch(ticketReservationChanged(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const createTicketReservation = (dispatch, csrfToken, team, confirm, orderType, ticketTypes, addOns, seatsIOChart, addOnSeatsIOChart) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var orderItems = ticketTypes.filter((tt) =>
      tt.quantity && tt.quantity > 0
    );

    var addOnOrderItems = addOns.filter((ao) =>
      ao.quantity && ao.quantity > 0
    );

    var complimentary = ["Complimentary", "No Payment Necessary"].includes(orderType);

    var seatsioHoldTokens = [];

    if(orderItems.some((i) => i.seatsIOObjects && i.seatsIOObjects.length > 0)){
      seatsioHoldTokens = [...seatsioHoldTokens, seatsIOChart.holdToken];
    }

    var addOnHoldTokens = [
      ...new Set(
        addOnOrderItems
          .filter((item) => item.seatsIOObjects)
          .map((item) => item.seatsIOObjects)
          .flat()
          .map((object) => object.hold_token)
      )
    ];

    seatsioHoldTokens = [
      ...seatsioHoldTokens,
      ...addOnHoldTokens
    ];

    return axios.post("/teams/" + team.id + "/manual_ticket_reservations", {
        ticket_reservation: {
          calendar_event_id: confirm.id,
          complimentary: complimentary,
          manual_type: orderType,
          seatsio_hold_tokens: seatsioHoldTokens,
          order_items_attributes: orderItems.map((item) => {
            return {
              ticket_type_id: item.id,
              quantity: item.quantity,
              face_value: parseFloatFromString(item.face_value),
              seatsio_objects: item.seatsIOObjects
            };
          }),
          add_on_order_items_attributes: addOnOrderItems.map((item) => {
            return {
              add_on_id: item.id,
              quantity: item.quantity,
              face_value: parseFloatFromString(item.face_value),
              seatsio_objects: item.seatsIOObjects
            };
          })
        }
      })
      .then(({ data }) => {
        dispatch(ticketReservationChanged(data));
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const updateTicketReservation = (dispatch, csrfToken, team, ticketReservation, onSuccess, customFieldValues) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch("/teams/" + team.id + "/manual_ticket_reservations/" + ticketReservation.token, {
        ticket_reservation: {
          first_name: ticketReservation.first_name,
          last_name: ticketReservation.last_name,
          email: ticketReservation.email,
          should_send_ticket_email: ticketReservation.should_send_ticket_email,
          cash_paid: ticketReservation.total,
          custom_field_values_attributes: customFieldValues.map((customFieldValue) => customFieldValueToParams(customFieldValue))
        }
      })
      .then(({ data }) => {
        var updated = Object.assign({}, ticketReservation, data);

        dispatch(ticketReservationChanged(updated));
        onSuccess(updated);
      })
      .catch((error) => {
        dispatch(isPlacingOrderChanged(false));

        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const isPlacingOrderChanged = (isPlacingOrder) => ({
  type: IS_PLACING_ORDER_CHANGED,
  isPlacingOrder
});

export const checkoutTimePercentageRemainingChanged = (percentage) => ({
  type: CHECKOUT_TIME_PERCENTAGE_REMAINING_CHANGED,
  percentage
});

export const timeLimitReachedChanged = (reached) => ({
  type: TIME_LIMIT_REACHED_CHANGED,
  reached
});

export const deleteTicketReservation = (dispatch, csrfToken, team, ticketReservation) => {
  return dispatch => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete("/teams/" + team.id + "/manual_ticket_reservations/" + ticketReservation.token)
      .then(({ data }) => {
        dispatch(cancelCheckout());
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };
};

export const cancelCheckout = () => ({
  type: CANCEL_CHECKOUT
});

export const customFieldValueChanged = (customFieldValue) => ({
  type: CUSTOM_FIELD_VALUE_CHANGED,
  customFieldValue
});

export const seatsIOChartChanged = (seatsIOChart) => ({
  type: SEATS_IO_CHART_CHANGED,
  seatsIOChart
});

export const seatsIOSelectedObjectsChanged = (seatsIOSelectedObjects) => ({
  type: SEATS_IO_SELECTED_OBJECTS_CHANGED,
  seatsIOSelectedObjects
});
