import { combineReducers } from 'redux';

import {
  CONFIRM_CHANGED,
  PROMO_CODE_NAME_CHANGED,
  PROMO_CODE_NAME_VALID_CHANGED,
  PROMO_CODE_CHANGED,
  TICKET_RESERVATION_CHANGED,
  TICKET_TYPES_CHANGED,
  CANCEL_CHECKOUT,
  STRIPE_PROMISE_CHANGED,
  STRIPE_ERROR_CHANGED,
  CHECKOUT_TIME_PERCENTAGE_REMAINING_CHANGED,
  TIME_LIMIT_REACHED_CHANGED,
  IS_PLACING_ORDER_CHANGED,
  ADD_ONS_CHANGED,
  GTAG_CHANGED,
  CUSTOM_FIELD_VALUE_CHANGED,
  SEATS_IO_CHART_CHANGED,
  SEATS_IO_SELECTED_OBJECTS_CHANGED,
  CUSTOMERIO_ID_CHANGED,
  IS_CREATING_TICKET_RESERVATION_CHANGED,
  INSURANCE_OFFER_STATE_CHANGED,
  TICKET_INSURANCE_CHANGED,
  HAS_INSURANCE_QUOTE_CHANGED,
  DONATION_CUSTOM_CHARGE_TYPE_CHANGED
} from '../constants/webOrderFormConstants';

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const confirm = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_CHANGED:
      return action.confirm;
    default:
      return state;
  }
};

const ticketTypes = (state = [], action) => {
  switch (action.type) {
    case TICKET_TYPES_CHANGED:
      return action.ticketTypes;
    case CANCEL_CHECKOUT:
      return [...state].map((tt) =>
        Object.assign({}, tt, {quantity: 0})
      );
    default:
      return state;
  }
};

const promoCode = (state = {}, action) => {
  switch (action.type) {
    case PROMO_CODE_CHANGED:
      return action.promoCode;
    case CANCEL_CHECKOUT:
      return {};
    default:
      return state;
  }
};

const promoCodeName = (state = "", action) => {
  switch (action.type) {
    case PROMO_CODE_CHANGED:
      return "";
    case PROMO_CODE_NAME_CHANGED:
      return action.name;
    case CANCEL_CHECKOUT:
      return "";
    default:
      return state;
  }
};

const promoCodeNameValid = (state = true, action) => {
  switch (action.type) {
    case PROMO_CODE_CHANGED:
      return true;
    case PROMO_CODE_NAME_VALID_CHANGED:
      return action.valid;
    case PROMO_CODE_NAME_CHANGED:
      return true;
    case CANCEL_CHECKOUT:
      return true;
    default:
      return state;
  }
};

const ticketReservation = (state = {}, action) => {
  switch (action.type) {
    case TICKET_RESERVATION_CHANGED:
      return action.ticketReservation;
    case CANCEL_CHECKOUT:
      return {};
    default:
      return state;
  }
};

const stripePromise = (state = {}, action) => {
  switch (action.type) {
    case STRIPE_PROMISE_CHANGED:
      return action.stripePromise;
    default:
      return state;
  }
};

const stripePublishableKey = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const stripeError = (state = {}, action) => {
  switch (action.type) {
    case CANCEL_CHECKOUT:
      return {};
    case STRIPE_ERROR_CHANGED:
      return action.error;
    default:
      return state;
  }
};

const rootUrl = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const checkoutTimePercentageRemaining = (state = 0, action) => {
  switch (action.type) {
    case CHECKOUT_TIME_PERCENTAGE_REMAINING_CHANGED:
      return action.percentage;
    case CANCEL_CHECKOUT:
      return 0;
    default:
      return state;
  }
};

const timeLimitReached = (state = false, action) => {
  switch (action.type) {
    case TIME_LIMIT_REACHED_CHANGED:
      return action.reached;
    default:
      return state;
  }
};

const isPlacingOrder = (state = false, action) => {
  switch (action.type) {
    case IS_PLACING_ORDER_CHANGED:
      return action.isPlacingOrder;
    case CANCEL_CHECKOUT:
      return false;
    default:
      return state;
  }
};

const addOns = (state = [], action) => {
  switch (action.type) {
    case ADD_ONS_CHANGED:
      return action.addOns;
    case CANCEL_CHECKOUT:
      return [...state].map((tt) =>
        Object.assign({}, tt, {
          seatsIOObjects: [],
          quantity: 0
        })
      );
    default:
      return state;
  }
};

const googleTrackingId = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const gtag = (state = () => {}, action) => {
  switch (action.type) {
    case GTAG_CHANGED:
      return action.gtag;
    default:
      return state;
  }
};

const customFieldValues = (state = [], action) => {
  switch (action.type) {
    case CUSTOM_FIELD_VALUE_CHANGED:
      return [...state].map((cf) => {
        if(cf.custom_field.id === action.customFieldValue.custom_field.id){
          return action.customFieldValue;
        } else {
          return cf;
        }
      });
    default:
      return state;
  }
};

const userContext = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const seatsIOPublicKey = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const seatsIOChart = (state = {}, action) => {
  switch (action.type) {
    case SEATS_IO_CHART_CHANGED:
      return action.seatsIOChart;
    default:
      return state;
  }
};

const seatsIOSelectedObjects = (state = [], action) => {
  switch (action.type) {
    case SEATS_IO_SELECTED_OBJECTS_CHANGED:
      return action.seatsIOSelectedObjects;
    case CANCEL_CHECKOUT:
      return [];
    default:
      return state;
  }
};

const customerioId = (state = "", action) => {
  switch (action.type) {
    case CUSTOMERIO_ID_CHANGED:
      return action.customerioId;
    default:
      return state;
  }
};

const isCreatingTicketReservation = (state = false, action) => {
  switch (action.type) {
    case IS_CREATING_TICKET_RESERVATION_CHANGED:
      return action.isCreatingTicketReservation;
    default:
      return state;
  }
};

const verticalInsureClientID = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const insuranceOfferState = (state = "", action) => {
  switch (action.type) {
    case INSURANCE_OFFER_STATE_CHANGED:
      return action.insuranceOfferState;
    default:
      return state;
  }
};

const ticketInsurance = (state = {}, action) => {
  switch (action.type) {
    case TICKET_INSURANCE_CHANGED:
      return action.ticketInsurance;
    default:
      return state;
  }
};

const hasInsuranceQuote = (state = false, action) => {
  switch (action.type) {
    case HAS_INSURANCE_QUOTE_CHANGED:
      return action.hasInsuranceQuote;
    default:
      return state;
  }
};

const announceListEnabled = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const waitlistEnabled = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const donationCustomChargeType = (state = {}, action) => {
  switch (action.type) {
    case DONATION_CUSTOM_CHARGE_TYPE_CHANGED:
      return action.donationCustomChargeType;
    case CANCEL_CHECKOUT:
      return {
        ...state,
        enteredPrice: undefined,
        price: undefined,
        coverProcessing: undefined
      };
    default:
      return state;
  }
};

const feeRules = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const startAtAddOns = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const startAtDonation = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const showHeader = (state = true, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const webOrderFormReducer = combineReducers({
  team,
  csrfToken,
  confirm,
  ticketTypes,
  promoCode,
  promoCodeName,
  promoCodeNameValid,
  ticketReservation,
  stripePromise,
  stripePublishableKey,
  stripeError,
  rootUrl,
  checkoutTimePercentageRemaining,
  timeLimitReached,
  isPlacingOrder,
  addOns,
  googleTrackingId,
  gtag,
  customFieldValues,
  userContext,
  seatsIOPublicKey,
  seatsIOChart,
  seatsIOSelectedObjects,
  customerioId,
  isCreatingTicketReservation,
  verticalInsureClientID,
  insuranceOfferState,
  ticketInsurance,
  hasInsuranceQuote,
  announceListEnabled,
  waitlistEnabled,
  donationCustomChargeType,
  feeRules,
  startAtAddOns,
  startAtDonation,
  showHeader
});

export default webOrderFormReducer;
