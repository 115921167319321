import React, { createContext, useState, useContext } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";

import SegmentNew from "./SegmentNew";
import { toastError } from "../../../shared/toastHelper";

const SegmentNewContext = createContext();

export function useSegmentNewContext() {
  return useContext(SegmentNewContext);
}

const SegmentNewProvider = (props) => {
  const {
    csrfToken,
    user,
    team,
  } = props;

  const [name, setName] = useState("");
  const [segmentType, setSegmentType] = useState("Segments::Manual");

  const createSegment = (payload) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.post(`/teams/${team.id}/segments`, payload)
      .then((data) => {
        window.location.href = `/teams/${team.id}/segments/${data["data"]["id"]}`;
      })
      .catch(() => toastError("Could not create broadcast, please try again"));
  };

  const value = {
    csrfToken,
    user,
    team,
    name,
    setName,
    segmentType,
    setSegmentType,
    createSegment,
  };

  return (
    <SegmentNewContext.Provider value={value}>
      {props.children}
    </SegmentNewContext.Provider>
  );
};

const SegmentNewContainer = (props) => (
  <SegmentNewProvider {...props}>
    <ToastContainer />
    <SegmentNew />
  </SegmentNewProvider>
);

export default SegmentNewContainer;
