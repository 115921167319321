import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';
var _ = require('lodash');

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

const ChooseDonation = ({
  team,
  csrfToken,
  confirm,
  buildTicketReservation,
  ticketReservation,
  donationCustomChargeType,
  donationCustomChargeTypeChanged,
  ticketTypes,
  addOns,
  promoCode,
  userContext,
  donationTitle,
  donationCoverProcessingText,
  debouncedBuildTicketReservation,
  feeRules
}) => {
  const calculateDonationAmount = (enteredPrice) => {
    var price = parseFloatFromString(enteredPrice);

    var feeRule = feeRules.find((fr) => {
      var min = parseFloatFromString(fr.minimum);
      var max = parseFloatFromString(fr.maximum);

      return (
        price >= min && price <= max
      )
    });

    var processingPercent = (parseFloatFromString(feeRule.processing_fee_percentage) / 100.0);
    var processingFixed = parseFloatFromString(feeRule.processing_fee_cash);

    if(donationCustomChargeType.coverProcessing){
      return price;
    } else {
      var processing = ((price * processingPercent) + processingFixed);
      var roundedProcessing = (Math.round(processing * 100) / 100);

      return (price - roundedProcessing);
    }
  }

  useEffect(() => {
    var hasEnteredPrice = (
      donationCustomChargeType
        && donationCustomChargeType.enteredPrice
        && donationCustomChargeType.enteredPrice.length > 0
    );

    var price;
    if(hasEnteredPrice){
      price = calculateDonationAmount(donationCustomChargeType.enteredPrice);
    } else {
      price = 0.00;
    }

    donationCustomChargeTypeChanged({
      ...donationCustomChargeType,
      price: price
    });

    if(debouncedBuildTicketReservation){
      debouncedBuildTicketReservation(csrfToken, confirm, ticketTypes, promoCode, addOns, ticketReservation, userContext);
    }
  }, [donationCustomChargeType.enteredPrice, donationCustomChargeType.coverProcessing]);

  var hasInvalidDonation = (
    donationCustomChargeType
      && donationCustomChargeType.enteredPrice
      && donationCustomChargeType.enteredPrice.length > 0
      && parseFloatFromString(donationCustomChargeType.enteredPrice) <= 0.50
  );

  return (
    <div>
      <div className="card border-0"
            style={{"background": "#f9f9f9", "marginBottom": "15px"}}>
        <div className="card-body"
              style={{
                "paddingLeft": "15px",
                "paddingRight": "15px"
              }}>
          <div className="row">
            <div className="col">
              <p className='mb-0' style={{"fontSize": "16px"}}>
                <strong>{donationTitle}</strong>
              </p>
              <p className='mb-2' style={{"fontSize": "16px"}}>
                Enter your donation amount.
              </p>
              <div className="input-group inline-white d-inline-flex"
                    style={{
                      "border": "1px solid #E5E5E5",
                      "borderRadius": "4px",
                      "maxWidth": "199px"
                    }}>
                <div className="input-group-prepend">
                  <span className="input-group-text px-2" id="offer-facility-maintenance-fee-dollar-sign">
                    <i className="far fa-dollar-sign text-muted" aria-hidden="true"></i>
                  </span>
                </div>
                <CurrencyInput className="form-control text-right"
                                maskOptions={{
                                  prefix: "",
                                  integerLimit: 5
                                }}
                                value={donationCustomChargeType.enteredPrice || ""}
                                onChange={
                                  (e) => {
                                    donationCustomChargeTypeChanged({
                                      ...donationCustomChargeType,
                                      enteredPrice: e.target.value
                                    })
                                  }
                                }
                                placeholder={"0"} />
              </div>
              {hasInvalidDonation ? (
                <p className='mb-0 mt-1 small text-danger'>
                  Minimum donation of $0.50 required
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="custom-control custom-checkbox custom-checkbox-table"
            style={{"marginBottom": "200px"}}>
        <input type="checkbox"
                className="custom-control-input"
                checked={donationCustomChargeType.coverProcessing || false}
                onChange={
                  (e) => {
                    donationCustomChargeTypeChanged({
                      ...donationCustomChargeType,
                      coverProcessing: e.target.checked
                    })
                  }
                }
                id="make-donation-cover-processing" />
        <label className="custom-control-label"
                style={{"fontSize": "14px"}}
                htmlFor="make-donation-cover-processing">
          {donationCoverProcessingText}
        </label>
      </div>
    </div>
  );
}

ChooseDonation.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  buildTicketReservation: PropTypes.func.isRequired,
  ticketReservation: PropTypes.object,
  donationCustomChargeType: PropTypes.object,
  donationCustomChargeTypeChanged: PropTypes.func.isRequired,
  ticketTypes: PropTypes.array,
  addOns: PropTypes.array,
  promoCode: PropTypes.object,
  userContext: PropTypes.object,
  debouncedBuildTicketReservation: PropTypes.func,
  feeRules: PropTypes.array.isRequired
};

export default ChooseDonation;

