/* eslint-disable import/prefer-default-export */

import {
  BUYERS_CHANGED,
  PROMOTERS_CHANGED,
  EVENT_DETAILS_CHANGED,
  HOLD_GROUP_HOLD_POSITION_UPDATED,
  HOLD_GROUP_HOLD_REMOVED,
  HOLD_GROUP_CALENDAR_DATE_CLICKED,
  HOLD_GROUP_HOLD_AVAILABILITY_UPDATED
} from '../constants/eventDetailsConstants';

import {
  offsetDateForServer
} from '../../../shared/timeZoneLogic';

const axios = require('axios').default;
import { toast } from 'react-toastify';

export const updateConfirm = (dispatch, csrfToken, team, confirm, eventDetails, successCallback) => {
  return (dispatch, getState) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    var patchUrl;
    var params;

    if(confirm.type === "Confirm") {
      patchUrl = `/teams/${team.id}/confirms/${confirm.id}`;
      params = {
        confirm: {
          title: eventDetails.title,
          buyer_id: eventDetails.buyer,
          promoter_id: eventDetails.promoter,
          event_template_id: eventDetails.event_template_id,
          ticket_forecast: eventDetails.ticket_forecast,
        }
      }
    } else {
      patchUrl = `/teams/${team.id}/hold_groups/${confirm.id}`;
      params = {
        hold: {
          title: eventDetails.title,
          buyer_id: eventDetails.buyer,
          promoter_id: eventDetails.promoter,
          event_template_id: eventDetails.event_template_id,
          ticket_forecast: eventDetails.ticket_forecast,
          hold_positions_attributes: (eventDetails.holdGroup.hold_group_entries || []).map((holdGroupEntry) => {
            return {
              id: (holdGroupEntry.hold.hold_position_id ? holdGroupEntry.hold.hold_position_id : ""),
              start_time: offsetDateForServer(holdGroupEntry.hold.start_time),
              position: holdGroupEntry.hold.position,
              _destroy: holdGroupEntry._destroy
            }
          })
        }
      }
    }

    return axios.patch(patchUrl, params)
    .then(({ data }) => {
      successCallback(data);
    })
    .catch((error) => {
      var message = error.response === undefined
        ? "Something went wrong"
        : error.response.data.join(', ');

      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    });
  };
};

export const eventDetailsChanged = (eventDetails) => ({
  type: EVENT_DETAILS_CHANGED,
  eventDetails
});

export const buyersChanged = (buyers) => ({
  type: BUYERS_CHANGED,
  buyers
});

export const promotersChanged = (promoters) => ({
  type: PROMOTERS_CHANGED,
  promoters
});

export const fetchBuyers = (dispatch, team, inputValue, callback) => {
  return dispatch => {
    axios.get(`/teams/${team.id}/companies/autocomplete`, {
      params: {
        name: inputValue
      }
    })
    .then(function (response) {
      var newOptions = response.data;

      if(!newOptions.map(option => option.label).includes(inputValue)) {
        newOptions.unshift({
          value: inputValue,
          label: `Add "${inputValue}"`,
          name: inputValue
        })
      }

      callback(newOptions);
    });
  };
};

export const createBuyer = (dispatch, team, buyer, successCallback, errorCallback) => {
  return dispatch => {
    axios.post(`/teams/${team.id}/companies`, {
      company: {
        name: buyer
      }
    })
    .then(response => successCallback(response.data))
    .catch(errorCallback);
  };
};

export const fetchPromoters = (dispatch, team, inputValue, callback) => {
  return dispatch => {
    axios.get(`/teams/${team.id}/promoters/autocomplete`, {
      params: {
        name: inputValue
      }
    })
    .then(function (response) {
      var newOptions = response.data;

      if(!newOptions.map(option => option.label).includes(inputValue)) {
        newOptions.unshift({
          value: inputValue,
          label: `Add "${inputValue}"`,
          name: inputValue
        })
      }

      callback(newOptions);
    });
  };
};

export const createPromoter = (dispatch, team, promoter, successCallback, errorCallback) => {
  return dispatch => {
    axios.post(`/teams/${team.id}/promoters`, {
      promoter: {
        name: promoter
      }
    })
    .then(response => successCallback(response.data))
    // .catch(errorCallback);
  };
};

export const fetchEventDetails = (dispatch, team, confirm) => {
  return dispatch => {
    axios.get(`/teams/${team.id}/calendar_events/${confirm.id}/edit`)
    .then(({ data }) => {
      var eventDetails = {
        ...data,
        promoter: (data.promoter ? data.promoter.value : undefined),
        buyer: (data.buyer ? data.buyer.value : undefined)
      }

      if(eventDetails.holdGroup){
        eventDetails.holdGroup.hold_group_entries.forEach((holdGroupEntry) => {
          holdGroupEntry.hold.start_time = new Date(holdGroupEntry.hold.start_time);
        });
      }

      dispatch(eventDetailsChanged(eventDetails));
    });
  };
};

export const holdGroupHoldPositionUpdated = (date, position) => ({
  type: HOLD_GROUP_HOLD_POSITION_UPDATED,
  date,
  position
});

export const holdGroupHoldRemoved = (date) => ({
  type: HOLD_GROUP_HOLD_REMOVED,
  date
})

export const holdGroupCalendarDateClicked = (date, selected) => ({
  type: HOLD_GROUP_CALENDAR_DATE_CLICKED,
  date,
  selected
});

export const fetchHoldAvailability = (dispatch, team, venueId, date) => {
  return dispatch => {
    return axios.get("/teams/" + team.id + "/holds/availity", {
        params: {
          venue_id: venueId,
          date: date
        }
      })
      .then(({ data }) => {
        dispatch(holdGroupHoldAvailabilityUpdated(team, date, data));
      })
  };
};

export const holdGroupHoldAvailabilityUpdated = (team, date, holdAvailability) => ({
  type: HOLD_GROUP_HOLD_AVAILABILITY_UPDATED,
  team,
  date,
  holdAvailability
})
