import React, { useEffect, useState } from 'react';

import { PUBLISH_MENU_ITEM } from "../constants/confirmTicketingConstants";
import { toastError } from "../../../shared/toastHelper";
import Popup from "reactjs-popup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const axios = require('axios').default;

const headerStyle = {
  fontSize: "22px",
  fontWeight: "strong",
  marginBottom: "20px"
};

const footerStyle = {
  width: "100%"
};

const buttonContainerStyle = {
  height: "130px"
};

const editCheckoutGroupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "650px",
  "padding": "0px"
}

const newCheckoutGroupItemContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "650px",
  "padding": "0px"
}

const editCategoriesContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "650px",
  "padding": "0px"
}

const CheckoutGroupItemRow = ({
  csrfToken,
  team,
  checkoutGroupItem,
  deleteCheckoutGroupItem,
  provided
}) => {
  return (
    <div className='row'
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            ...provided.draggableProps.style
          }}>
      <div className='col-12 small'>
        <div className='card rounded-0 mb-1' style={{"borderWidth": "1px"}}>
          <div className={'card-body p-3 d-flex justify-content-between ' + (checkoutGroupItem.item_id ? "align-items-center" : "align-items-start")}>
            <a {...provided.dragHandleProps}
              onClick={
                (e) => {
                  e.preventDefault();
                }
              }
              href="#"
              className={"btn btn-link btn-sm small text-muted"}>
              <i className="fas fa-grip-vertical"></i>
            </a>
            <div className='flex-grow-1'>
              {checkoutGroupItem.enable_donation ? (
                <div className='card mb-0 border-0' style={{"background": "#EEEEEE"}}>
                  <div className='card-body p-5 text-center'>
                    <strong>MAKE A DONATION</strong>
                  </div>
                </div>
              ) : checkoutGroupItem.seatsio_event_id && checkoutGroupItem.seatsio_event_id.length > 0 ? (
                <div className='card mb-0 border-0' style={{"background": "#EEEEEE"}}>
                  <div className='card-body p-5 text-center'>
                    <strong>ADMISSION SEATING CHART</strong>
                  </div>
                </div>
              ) : checkoutGroupItem.add_on_seatsio_event_id && checkoutGroupItem.add_on_seatsio_event_id.length > 0 ? (
                <div className='card mb-0 border-0' style={{"background": "#EEEEEE"}}>
                  <div className='card-body p-5 text-center'>
                    <strong>ADD-ON SEATING CHART</strong>
                  </div>
                </div>
              ) : checkoutGroupItem.item_type === "TicketType" ? (
                <div>
                  <p className='mb-0'>
                    <strong>{checkoutGroupItem.item_name}</strong>
                  </p>
                  <p className="text-muted mb-0" style={{"fontSize": "10px", "marginTop": "-3px"}}>
                    TICKET TYPE
                  </p>
                </div>
              ) : checkoutGroupItem.item_type === "AddOn" ? (
                <div>
                  <p className='mb-0'>
                    <strong>{checkoutGroupItem.item_name}</strong>
                  </p>
                  <p className="text-muted mb-0" style={{"fontSize": "10px", "marginTop": "-3px"}}>
                    ADD-ON
                  </p>
                </div>
              ) :null}
            </div>
            <a href="#"
                onClick={
                  (e) => {
                    e.preventDefault();
                    deleteCheckoutGroupItem(team, csrfToken, checkoutGroupItem);
                  }
                }
                className='text-muted'>
              <i className="fas fa-trash ml-2"></i>              
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

const CheckoutGroupRow = ({
  csrfToken,
  team,
  checkoutGroup,
  setCheckoutGroupToEdit,
  deleteCheckoutGroup,
  provided,
  deleteCheckoutGroupItem,
  setNewCheckoutGroupItem,
  updateCheckoutGroupItemPosition,
  checkoutCategory,
  checkoutCategories,
  checkoutCategoriesChanged
}) => {
  return (
    <div className={"card rounded-0"}
          ref={provided.innerRef}
          {...provided.draggableProps}
          style={{
            ...provided.draggableProps.style,
            "borderWidth": "1px"
          }}>
      <div className="card-header d-flex justify-content-between">
        <p className='mb-0'
            style={{"fontSize": "16px"}}>
          <a {...provided.dragHandleProps}
            onClick={
              (e) => {
                e.preventDefault();
              }
            }
            href="#"
            className={"btn btn-link btn-sm small text-muted"}>
            <i className="fas fa-grip-vertical"></i>
          </a>
          <strong>{checkoutGroup.name}</strong>
        </p>
        <div>
          <a href="#"
              className='text-muted'
              onClick={
                (e) => {
                  e.preventDefault();
                  setCheckoutGroupToEdit(checkoutGroup);
                }
              }>
            <i className="fas fa-edit ml-2"></i>
          </a>
          <a href="#"
              onClick={
                (e) => {
                  e.preventDefault();

                  if(window.confirm("Are you sure you want to delete this group?")) {
                    deleteCheckoutGroup(team, csrfToken, checkoutGroup);
                  }
                }
              }
              className='text-muted'>
            <i className="fas fa-trash ml-2"></i>
          </a>
        </div>
      </div>
      {checkoutGroup.description && checkoutGroup.description.length > 0 ? (
        <div className='card-body' style={{"borderBottom": "2px solid rgba(51, 51, 51, 0.125)"}}>
          <div className='small'
                dangerouslySetInnerHTML={{__html: checkoutGroup.formatted_description}}>
          </div>
        </div>
      ) : null}
      <div className='card-body'>
        <DragDropContext onDragEnd={
                            (result) => {
                              if (!result.destination) {
                                return false;
                              }

                              var itemIndex = result.source.index;
                              var moveIndex = result.destination.index;
                              var updatedCheckoutGroupItem = Object.assign({}, checkoutGroup.checkout_group_items[itemIndex], {
                                position: (moveIndex + 1)
                              });

                              var checkoutGroupItemsCopy = [...checkoutGroup.checkout_group_items];
                              var [removed] = checkoutGroupItemsCopy.splice(itemIndex, 1);
                              checkoutGroupItemsCopy.splice(moveIndex, 0, removed);

                              var updatedGroupItems = checkoutGroupItemsCopy.map((category, index) =>
                                Object.assign({}, category, {position: (index + 1)})
                              );

                              var updatedGroup = {
                                ...checkoutGroup,
                                checkout_group_items: updatedGroupItems
                              };

                              var updatedCategory = {
                                ...checkoutCategory,
                                checkout_groups: [...checkoutCategory.checkout_groups].map((group) => {
                                  if (group.id === updatedGroup.id) {
                                    return updatedGroup;
                                  } else {
                                    return group;
                                  }
                                })
                              }
                                
                              var updated = checkoutCategories.map((category) => {
                                if (category.id === updatedCategory.id) {
                                  return updatedCategory;
                                } else {
                                  return category;
                                }
                              });

                              checkoutCategoriesChanged(updated);
                              updateCheckoutGroupItemPosition(team, csrfToken, updatedCheckoutGroupItem);
                            }
                          }>
          <Droppable droppableId={`checkout-group-items-list-${checkoutGroup.id}`}>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {checkoutGroup.checkout_group_items.sort((a, b) => a.position - b.position).map((checkoutGroupItem, index) =>
                  <Draggable key={"" + checkoutGroupItem.id} draggableId={"" + checkoutGroupItem.id} index={index}>
                    {(provided, snapshot) => (
                      <CheckoutGroupItemRow key={index}
                                            team={team}
                                            csrfToken={csrfToken}
                                            deleteCheckoutGroupItem={deleteCheckoutGroupItem}
                                            provided={provided}
                                            checkoutGroupItem={checkoutGroupItem} />
                    )}
                  </Draggable>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <a href="#"
            onClick={
              (e) => {
                e.preventDefault();
                setNewCheckoutGroupItem({
                  checkout_group_id: checkoutGroup.id,
                  item_type: "AddOn"
                });
              }
            }
            className='btn btn-external btn-sm btn-block rounded small'>
          <i className="fas fa-plus mr-2"></i>
          <strong>Add Item</strong>
        </a>
      </div>
    </div>
  );
}

const Customize = ({
  csrfToken,
  team,
  confirm,
  confirmChanged,
  autoSaveConfirm,
  activeMenuItemChanged,
  checkoutCategories,
  checkoutCategoriesChanged,
  ticketTypes,
  addOns
}) => {
  const [widgetType, setWidgetType] = useState(
    checkoutCategories.length > 0 ? "festival" : "club"
  );
  const [checkoutCategoryToEdit, setCheckoutCategoryToEdit] = useState({});
  const [checkoutGroupToEdit, setCheckoutGroupToEdit] = useState({});
  const [editingCategories, setEditingCategories] = useState(false);
  const [newCheckoutGroupItem, setNewCheckoutGroupItem] = useState({});

  useEffect(() => {
    var activeCategory = checkoutCategories
      .find((category) => category._active);

    if(!activeCategory && checkoutCategories.length > 0) {
      var updated = [...checkoutCategories].map((category, index) => {
        if (index === 0) {
          return {...category, _active: true};
        } else {
          return {...category, _active: false};
        }
      });

      checkoutCategoriesChanged(updated);
    }
  }, [checkoutCategories]);

  const validCheckoutCategory = (checkoutCategory) => {
    return (
      checkoutCategory.name
        && checkoutCategory.name.length > 0
    )
  };

  const createCheckoutCategory = (team, csrfToken, confirm, name) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post(`/teams/${team.id}/checkout_categories`, {
      checkout_category: {
          calendar_event_id: confirm.id,
          name: name
        }
      })
      .then(({ data }) => {
        checkoutCategoriesChanged([...checkoutCategories, data]);
      })
      .catch(
        error => toastError("Something went wrong. Try again.")
      );
  };

  const updateCheckoutCategory = (team, csrfToken, checkoutCategory) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch(`/teams/${team.id}/checkout_categories/${checkoutCategory.id}`, {
      checkout_category: {
          name: checkoutCategory.name
        }
      })
      .then(({ data }) => {
        var updated = [...checkoutCategories].map((category) => {
          if (category.id === checkoutCategory.id) {
            return data;
          } else {
            return category;
          }
        });

        checkoutCategoriesChanged(updated);
        setCheckoutCategoryToEdit({});
      })
      .catch(
        error => toastError("Something went wrong. Try again.")
      );
  };

  const updateCheckoutCategoryPosition = (team, csrfToken, checkoutCategory) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch(`/teams/${team.id}/checkout_categories/${checkoutCategory.id}`, {
      checkout_category: {
          position: checkoutCategory.position
        }
      })
      .catch(
        error => toastError("Something went wrong. Try again.")
      );
  };

  const deleteCheckoutCategory = (team, csrfToken, checkoutCategory) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete(`/teams/${team.id}/checkout_categories/${checkoutCategory.id}`)
      .then(({ data }) => {
        var updated = checkoutCategories
          .filter((category) => category.id !== checkoutCategory.id);

        checkoutCategoriesChanged(updated);
      })
      .catch(
        error => toastError("Something went wrong. Try again.")
      );
  };

  const validCheckoutGroup = (checkoutCategory) => {
    return (
      checkoutCategory.checkout_category_id
        && checkoutCategory.name
        && checkoutCategory.name.length > 0
    )
  };

  const createCheckoutGroup = (team, csrfToken, checkoutCategory) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post(`/teams/${team.id}/checkout_groups`, {
        checkout_group: {
          checkout_category_id: checkoutCategory.id,
          name: "New Group"
        }
      })
      .then(({ data }) => {
        var updatedCategory = {
          ...checkoutCategory,
          checkout_groups: [...checkoutCategory.checkout_groups, data]
        }

        var updated = [...checkoutCategories].map((category) => {
          if (category.id === checkoutCategory.id) {
            return updatedCategory;
          } else {
            return category;
          }
        });

        checkoutCategoriesChanged(updated);
      })
      .catch(
        error => toastError("Something went wrong. Try again.")
      );
  };

  const updateCheckoutGroup = (team, csrfToken, checkoutGroup) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch(`/teams/${team.id}/checkout_groups/${checkoutGroup.id}`, {
        checkout_group: {
          name: checkoutGroup.name,
          description: checkoutGroup.description
        }
      })
      .then(({ data }) => {
        var checkoutCategory = checkoutCategories
          .find((category) => category.id === data.checkout_category_id);

        var updatedCheckoutGroups = checkoutCategory.checkout_groups.map((group) => {
          if (group.id === checkoutGroup.id) {
            return data;
          } else {
            return group;
          }
        });

        var updatedCategory = {
          ...checkoutCategory,
          checkout_groups: updatedCheckoutGroups
        }

        var updated = [...checkoutCategories].map((category) => {
          if (category.id === checkoutCategory.id) {
            return updatedCategory;
          } else {
            return category;
          }
        });

        checkoutCategoriesChanged(updated);
      })
      .catch(
        error => toastError("Something went wrong. Try again.")
      );
  };

  const updateCheckoutGroupPosition = (team, csrfToken, checkoutGroup) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch(`/teams/${team.id}/checkout_groups/${checkoutGroup.id}`, {
        checkout_group: {
          position: checkoutGroup.position
        }
      })
      .catch(
        error => toastError("Something went wrong. Try again.")
      );
  };

  const deleteCheckoutGroup = (team, csrfToken, checkoutGroup) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete(`/teams/${team.id}/checkout_groups/${checkoutGroup.id}`)
      .then(({ data }) => {
        var checkoutCategory = checkoutCategories
          .find((category) => category.id === data.checkout_category_id);

        var updatedCheckoutGroups = checkoutCategory.checkout_groups
          .filter((group) => group.id !== checkoutGroup.id);

        var updatedCategory = {
          ...checkoutCategory,
          checkout_groups: updatedCheckoutGroups
        }

        var updated = [...checkoutCategories].map((category) => {
          if (category.id === checkoutCategory.id) {
            return updatedCategory;
          } else {
            return category;
          }
        });

        checkoutCategoriesChanged(updated);
      })
      .catch(
        error => toastError("Something went wrong. Try again.")
      );
  };

  const validCheckoutGroupItem = (checkoutGroupItem) => {
    return (
      checkoutGroupItem.checkout_group_id
        && (
          (checkoutGroupItem.item_type && checkoutGroupItem.item_id)
            || checkoutGroupItem.enable_donation
            || checkoutGroupItem.seatsio_event_id
            || checkoutGroupItem.add_on_seatsio_event_id
        )
    )
  };

  const createCheckoutGroupItem = (team, csrfToken, checkoutGroupItem) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.post(`/teams/${team.id}/checkout_group_items`, {
        checkout_group_item: {
          checkout_group_id: checkoutGroupItem.checkout_group_id,
          item_id: checkoutGroupItem.item_id,
          item_type: checkoutGroupItem.item_type,
          seatsio_event_id: checkoutGroupItem.seatsio_event_id,
          add_on_seatsio_event_id: checkoutGroupItem.add_on_seatsio_event_id,
          enable_donation: checkoutGroupItem.enable_donation
        }
      })
      .then(({ data }) => {
        var checkoutGroup = checkoutCategories
          .map((category) => category.checkout_groups)
          .flat()
          .find((group) => group.id === data.checkout_group_id);

        var checkoutCategory = checkoutCategories
          .find((category) => category.id === checkoutGroup.checkout_category_id);

        var updatedCheckoutCategory = {
          ...checkoutCategory,
          checkout_groups: checkoutCategory.checkout_groups.map((group) => {
            if (group.id === checkoutGroup.id) {
              return {
                ...group,
                checkout_group_items: [...group.checkout_group_items, data]
              }
            } else {
              return group;
            }
          })
        }

        var updated = [...checkoutCategories].map((category) => {
          if (category.id === checkoutCategory.id) {
            return updatedCheckoutCategory;
          } else {
            return category;
          }
        });

        checkoutCategoriesChanged(updated);
      })
      .catch(
        error => toastError("Something went wrong. Try again.")
      );
  };

  const updateCheckoutGroupItemPosition = (team, csrfToken, checkoutGroupItem) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.patch(`/teams/${team.id}/checkout_group_items/${checkoutGroupItem.id}`, {
        checkout_group_item: {
          position: checkoutGroupItem.position
        }
      })
      .catch(
        error => toastError("Something went wrong. Try again.")
      );
  };

  const deleteCheckoutGroupItem = (team, csrfToken, checkoutGroupItem) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    return axios.delete(`/teams/${team.id}/checkout_group_items/${checkoutGroupItem.id}`)
      .then(({ data }) => {
        var checkoutGroup = checkoutCategories
          .map((category) => category.checkout_groups)
          .flat()
          .find((group) => group.id === data.checkout_group_id);

        var checkoutCategory = checkoutCategories
          .find((category) => category.id === checkoutGroup.checkout_category_id);

        var updatedCheckoutCategory = {
          ...checkoutCategory,
          checkout_groups: checkoutCategory.checkout_groups.map((group) => {
            if (group.id === checkoutGroup.id) {
              return {
                ...group,
                checkout_group_items: group.checkout_group_items.filter((item) => item.id !== data.id)
              }
            } else {
              return group;
            }
          })
        }

        var updated = [...checkoutCategories].map((category) => {
          if (category.id === checkoutCategory.id) {
            return updatedCheckoutCategory;
          } else {
            return category;
          }
        });

        checkoutCategoriesChanged(updated);
      })
      .catch(
        error => toastError("Something went wrong. Try again.")
      );
  };

  return (
    <div className="row">
      <div className="col-12"
            style={{
              "borderBottom": "1px solid #F6F6F6",
              "marginBottom": "20px",
            }}>
        <h3 style={headerStyle}>
          <strong>
            Customize
          </strong>
        </h3>
      </div>
      <div className="col-12">
        <div className='row' style={{"marginBottom": "20px"}}>
          <div className='col-12'>
            <label htmlFor="widget-type">Choose a widget type</label>
            <div className="d-flex flex-wrap">
              <button
                type="button"
                className="d-flex align-items-center mr-4 mb-4 p-3"
                style={{
                  width: "300px",
                  minHeight: "96px",
                  backgroundColor: widgetType === "club" ? "#eef7fd" : "#fff",
                  border: widgetType === "club" ? "2px solid #1982C4" : "2px solid #ccc",
                  borderRadius: "10px",
                }}
                onClick={
                  () => {
                    if(checkoutCategories.length > 0 && !window.confirm("Are you sure? Changing the widget type will delete all tabs and groups.")) {
                      return false;
                    }

                    setWidgetType("club");

                    checkoutCategories.map((category, index) => {
                      deleteCheckoutCategory(team, csrfToken, category);
                    });
                  }
                }
              >
                <div style={{ fontSize: "16px" }}> <i className="far fa-compact-disc mr-1" />
                </div>
                <div className="ml-3 text-left">
                  <p className="mb-0" style={{ fontSize: "16px" }}>
                    <strong>
                      Club
                    </strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "12px" }}>
                    The default widget layout ideal for most events. Patron is guided step-by-step.
                  </p>
                </div>
              </button>
              <button
                type="button"
                className="d-flex align-items-center mr-4 mb-4 p-3"
                style={{
                  width: "300px",
                  minHeight: "96px",
                  backgroundColor: widgetType === "festival" ? "#eef7fd" : "#fff",
                  border: widgetType === "festival" ? "2px solid #1982C4" : "2px solid #ccc",
                  borderRadius: "10px",
                }}
                onClick={
                  () => {
                    setWidgetType("festival");
                    createCheckoutCategory(team, csrfToken, confirm, "Tickets");
                  }
                }
              >
                <div style={{ fontSize: "16px" }}> <i className="far fa-trees mr-1" />
                </div>
                <div className="ml-3 text-left">
                  <p className="mb-0" style={{ fontSize: "16px" }}>
                    <strong>
                      Festival
                    </strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "12px" }}>
                    This widget, ideal for festival events, offers more customizable options
                  </p>
                </div>
              </button>
            </div>
          </div>
        </div>
        {widgetType === "festival" ? (
          <>
            <div className='row'>
              <div className='col-12'>
                <ul className="nav inverse-nav-tabs px-3 bottom-border"
                    style={{"marginBottom": "20px"}}>
                  {checkoutCategories.map((category, index) =>
                    <li key={index}
                        className="nav-item"
                        style={{"marginBottom": "-2px"}}>
                      <a className={"nav-link pb-3 " + (category._active ? "active" : "")}
                          onClick={
                            (e) => {
                              e.preventDefault();

                              var updated = [...checkoutCategories].map((category, i) => {
                                if (index === i) {
                                  return {...category, _active: true};
                                } else {
                                  return {...category, _active: false};
                                }
                              });
                        
                              checkoutCategoriesChanged(updated);
                            }
                          }
                          href="#">
                        <strong>{category.name}</strong>
                      </a>
                    </li>
                  )}
                  <li className="nav-item"
                        style={{"marginBottom": "-2px"}}>
                      <a className="nav-link pb-3 text-primary"
                          onClick={
                            (e) => {
                              e.preventDefault();
                              setEditingCategories(true);
                            }
                          }
                          href="#">
                        <i className="fas fa-cog mr-2"></i>
                        <strong>Configure</strong>
                      </a>
                    </li>
                </ul>
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                {checkoutCategories.map((category, categoryIndex) =>
                  <div key={categoryIndex}
                        className={(category._active ? "" : "d-none")}>
                    <DragDropContext onDragEnd={
                                        (result) => {
                                          if (!result.destination) {
                                            return false;
                                          }

                                          var itemIndex = result.source.index;
                                          var moveIndex = result.destination.index;
                                          var updatedCheckoutGroup = Object.assign({}, category.checkout_groups[itemIndex], {
                                            position: (moveIndex + 1)
                                          });

                                          var checkoutGroupsCopy = [...category.checkout_groups];
                                          var [removed] = checkoutGroupsCopy.splice(itemIndex, 1);
                                          checkoutGroupsCopy.splice(moveIndex, 0, removed);

                                          var updatedGroups = checkoutGroupsCopy.map((category, index) =>
                                            Object.assign({}, category, {position: (index + 1)})
                                          );

                                          var updatedCategory = {
                                            ...category,
                                            checkout_groups: updatedGroups
                                          }
                                            
                                          var updated = checkoutCategories.map((category) => {
                                            if (category.id === updatedCategory.id) {
                                              return updatedCategory;
                                            } else {
                                              return category;
                                            }
                                          });

                                          checkoutCategoriesChanged(updated);
                                          updateCheckoutGroupPosition(team, csrfToken, updatedCheckoutGroup);
                                        }
                                      }>
                      <Droppable droppableId={`checkout-group-list-${category.id}`}>
                        {(provided, snapshot) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {category.checkout_groups.sort((a, b) => a.position - b.position).map((checkoutGroup, index) =>
                              <Draggable key={"" + checkoutGroup.id} draggableId={"" + checkoutGroup.id} index={index}>
                                {(provided, snapshot) => (
                                  <CheckoutGroupRow key={index}
                                      csrfToken={csrfToken}
                                      team={team}
                                      setCheckoutGroupToEdit={setCheckoutGroupToEdit}
                                      deleteCheckoutGroup={deleteCheckoutGroup}
                                      provided={provided}
                                      deleteCheckoutGroupItem={deleteCheckoutGroupItem}
                                      updateCheckoutGroupItemPosition={updateCheckoutGroupItemPosition}
                                      setNewCheckoutGroupItem={setNewCheckoutGroupItem}
                                      checkoutCategory={category}
                                      checkoutCategories={checkoutCategories}
                                      checkoutCategoriesChanged={checkoutCategoriesChanged}
                                      checkoutGroup={checkoutGroup} />
                                )}
                              </Draggable>
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    <a href="#"
                        onClick={
                          (e) => {
                            e.preventDefault();
                            createCheckoutGroup(team, csrfToken, category);
                          }
                        }
                        className='btn btn-external btn-block rounded'>
                      <i className="fas fa-plus mr-2"></i>
                      <strong>Add Group</strong>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div className="row" style={footerStyle}>
        <div className="col-12 d-flex justify-content-end align-items-end" style={buttonContainerStyle}>
          <a href="#"
              onClick={
                (e) => {
                  e.preventDefault();
                  activeMenuItemChanged(PUBLISH_MENU_ITEM);
                }
              }
              className={"btn btn-primary"}>
            <strong>Next</strong>
          </a>
        </div>
      </div>
      <Popup
        open={editingCategories}
        contentStyle={editCategoriesContentStyle}
        className={"popup-modal"}
        closeOnDocumentClick
        onClose={
          () => {
            setEditingCategories(false);
            setCheckoutCategoryToEdit({});
          }
        }
      >
        <div className="popup-modal-container">
          <div className="row m-0">
            <div className="col d-flex align-items-center justify-content-between"
                  style={{
                    "borderBottom": "1px solid #EEEEEE",
                    "padding": "20px"
                  }}>
              <p className='mb-0'
                  style={{"fontSize": "20px"}}>
                <strong>Edit Tabs</strong>
              </p>
              <a href="#"
                className="text-muted"
                style={{
                  "paddingRight": "15px",
                  "fontSize": "20px"
                }}
                onClick={
                  (e) => {
                    e.preventDefault();
                    setEditingCategories(false);
                  }
                }>
                <i className="fa fa-times"></i>
              </a>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-12" style={{"padding": "20px"}}>
              <DragDropContext onDragEnd={
                                  (result) => {
                                    if (!result.destination) {
                                      return false;
                                    }

                                    var itemIndex = result.source.index;
                                    var moveIndex = result.destination.index;
                                    var updatedCheckoutCategory = Object.assign({}, checkoutCategories[itemIndex], {
                                      position: (moveIndex + 1)
                                    });

                                    var checkoutCategoriesCopy = [...checkoutCategories];
                                    var [removed] = checkoutCategoriesCopy.splice(itemIndex, 1);
                                    checkoutCategoriesCopy.splice(moveIndex, 0, removed);

                                    var updated = checkoutCategoriesCopy.map((category, index) =>
                                      Object.assign({}, category, {position: (index + 1)})
                                    );

                                    checkoutCategoriesChanged(updated);
                                    updateCheckoutCategoryPosition(team, csrfToken, updatedCheckoutCategory);
                                  }
                                }>
                <Droppable droppableId="checkout-category-list">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {checkoutCategories.sort((a, b) => a.position - b.position).map((category, index) =>
                        <Draggable key={"" + category.id} draggableId={"" + category.id} index={index}>
                          {(provided, snapshot) => (
                            <div className='card mb-2'
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    "borderWidth": "1px"
                                  }}
                                  key={index}>
                              {category.id === checkoutCategoryToEdit.id ? (
                                <div className='card-body p-3 d-flex justify-content-between align-items-center'>
                                  <a {...provided.dragHandleProps}
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                      }
                                    }
                                    href="#"
                                    className={"btn btn-link btn-sm small text-muted"}>
                                    <i className="fas fa-grip-vertical"></i>
                                  </a>
                                  <form className="form-inline d-flex flex-grow-1"
                                        onSubmit={
                                          (e) => {
                                            e.preventDefault();

                                            if(validCheckoutCategory(checkoutCategoryToEdit)){
                                              updateCheckoutCategory(team, csrfToken, checkoutCategoryToEdit);
                                            }
                                          }
                                        }>
                                    <div className="input-group mb-0 flex-grow-1">
                                      <input type="text"
                                            className="form-control form-control-sm"
                                            style={{"borderWidth": "1px"}}
                                            autoFocus={true}
                                            value={checkoutCategoryToEdit.name || ""}
                                            onChange={
                                              (e) => {
                                                setCheckoutCategoryToEdit({
                                                  ...checkoutCategoryToEdit,
                                                  name: e.target.value
                                                });
                                              }
                                            }
                                            placeholder="Tab Name" />
                                    </div>
                                    <a href="#"
                                        onClick={
                                          (e) => {
                                            e.preventDefault();
                                            setCheckoutCategoryToEdit({});
                                          }
                                        }
                                        className='ml-2'>
                                      Cancel
                                    </a>
                                    <button type="submit"
                                            disabled={!validCheckoutCategory(checkoutCategoryToEdit)}
                                            className="btn btn-primary btn-sm ml-2">
                                      Save
                                    </button>
                                  </form>
                                </div>
                              ) : (
                                <div className='card-body p-3 d-flex justify-content-between align-items-center'>
                                  <a {...provided.dragHandleProps}
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                      }
                                    }
                                    href="#"
                                    className={"btn btn-link btn-sm small text-muted"}>
                                    <i className="fas fa-grip-vertical"></i>
                                  </a>
                                  <div className='text-truncate flex-grow-1'>
                                    {category.name}
                                  </div>
                                  <div>
                                    <a href="#"
                                        className='text-muted'
                                        onClick={
                                          (e) => {
                                            e.preventDefault();
                                            setCheckoutCategoryToEdit(category);
                                          }
                                        }>
                                      <i className="fas fa-edit ml-2"></i>
                                    </a>
                                    <a href="#"
                                        onClick={
                                          (e) => {
                                            e.preventDefault();
                                            
                                            if(window.confirm("Are you sure you want to delete this tab?")) {
                                              deleteCheckoutCategory(team, csrfToken, category);
                                            }
                                          }
                                        }
                                        className='text-muted'>
                                      <i className="fas fa-trash ml-2"></i>
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </Draggable>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              <a href="#"
                  onClick={
                    (e) => {
                      e.preventDefault();
                      createCheckoutCategory(team, csrfToken, confirm, "New Tab");
                    }
                  }
                  className='btn btn-external btn-block rounded'>
                <strong>Add Tab</strong>
              </a>
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        open={Object.keys(checkoutGroupToEdit).length > 0}
        contentStyle={editCheckoutGroupContentStyle}
        className={"popup-modal"}
        closeOnDocumentClick
        onClose={
          () => setCheckoutGroupToEdit({})
        }
      >
        <div className="popup-modal-container">
          <div className="row m-0">
            <div className="col d-flex align-items-center justify-content-between"
                  style={{
                    "borderBottom": "1px solid #EEEEEE",
                    "padding": "20px"
                  }}>
              <p className='mb-0'
                  style={{"fontSize": "20px"}}>
                <strong>Edit Group</strong>
              </p>
              <a href="#"
                className="text-muted"
                style={{
                  "paddingRight": "15px",
                  "fontSize": "20px"
                }}
                onClick={
                  (e) => {
                    e.preventDefault();
                    setCheckoutGroupToEdit({});
                  }
                }>
                <i className="fa fa-times"></i>
              </a>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-12" style={{"padding": "20px"}}>
              <form onSubmit={
                      (e) => {
                        e.preventDefault();

                        if(validCheckoutGroup(checkoutGroupToEdit)){
                          updateCheckoutGroup(team, csrfToken, checkoutGroupToEdit);
                          setCheckoutGroupToEdit({});
                        }
                      }
                    }>
                <div className="form-group">
                  <label className='mb-1'
                          htmlFor="checkout-group-name">
                    Name
                  </label>
                  <input type="text"
                          className="form-control"
                          autoFocus={true}
                          style={{
                            "borderRadius": "8px",
                            "borderWidth": "1px",
                            "fontSize": "14px"
                          }}
                          value={checkoutGroupToEdit.name || ""}
                          onChange={
                            (e) => {
                              setCheckoutGroupToEdit({
                                ...checkoutGroupToEdit,
                                name: e.target.value
                              });
                            }
                          }
                          id="checkout-group-name"
                          placeholder="Name" />
                </div>
                <div className="form-group">
                  <label className='mb-1'
                          htmlFor="checkout-group-description">
                    Description
                  </label>
                  <textarea className="form-control"
                            rows={10}
                            style={{
                              "borderRadius": "8px",
                              "borderWidth": "1px",
                              "fontSize": "12px"
                            }}
                            value={checkoutGroupToEdit.description || ""}
                            onChange={
                              (e) => {
                                setCheckoutGroupToEdit({
                                  ...checkoutGroupToEdit,
                                  description: e.target.value
                                });
                              }
                            }
                            id="checkout-group-description"
                            placeholder="Description" />
                </div>
                <div className='row'>
                  <div className='col-12 text-right'>
                    <button type="submit"
                            disabled={!validCheckoutGroup(checkoutGroupToEdit)}
                            className="btn btn-primary btn-sm">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Popup>
      <Popup
        open={Object.keys(newCheckoutGroupItem).length > 0}
        contentStyle={newCheckoutGroupItemContentStyle}
        className={"popup-modal"}
        closeOnDocumentClick
        onClose={
          () => setNewCheckoutGroupItem({})
        }
      >
        <div className="popup-modal-container">
          <div className="row m-0">
            <div className="col d-flex align-items-center justify-content-between"
                  style={{
                    "borderBottom": "1px solid #EEEEEE",
                    "padding": "20px"
                  }}>
              <p className='mb-0'
                  style={{"fontSize": "20px"}}>
                <strong>Add Item</strong>
              </p>
              <a href="#"
                className="text-muted"
                style={{
                  "paddingRight": "15px",
                  "fontSize": "20px"
                }}
                onClick={
                  (e) => {
                    e.preventDefault();
                    setNewCheckoutGroupItem({});
                  }
                }>
                <i className="fa fa-times"></i>
              </a>
            </div>
          </div>
          <div className="row m-0">
            <div className="col-12" style={{"padding": "20px"}}>
              <form onSubmit={
                      (e) => {
                        e.preventDefault();

                        if(validCheckoutGroupItem(newCheckoutGroupItem)){
                          createCheckoutGroupItem(team, csrfToken, newCheckoutGroupItem);
                          setNewCheckoutGroupItem({});
                        }
                      }
                    }>
                <div className="form-group">
                  <label className='mb-1'
                          htmlFor="checkout-group-item-category">
                    Type
                  </label>
                  <select className="form-control"
                          style={{
                            "borderRadius": "8px",
                            "borderWidth": "1px",
                            "fontSize": "14px"
                          }}
                          value={newCheckoutGroupItem.strategy || ""}
                          onChange={
                            (e) => {
                              var newValue = e.target.value;
                              var attrs = {
                                strategy: newValue,
                                item_type: "",
                                item_id: "",
                                enable_donation: false,
                                seatsio_event_id: "",
                                add_on_seatsio_event_id: ""
                              };

                              switch (newValue) {
                                case 'AddOn':
                                  attrs = Object.assign(attrs, {item_type: "AddOn"});
                                  break;
                                case 'Donation':
                                  attrs = Object.assign(attrs, {enable_donation: true});
                                  break;
                                case 'TicketType':
                                  attrs = Object.assign(attrs, {item_type: "TicketType"});
                                  break;
                                case 'Seated Add-on Chart':
                                  attrs = Object.assign(attrs, {add_on_seatsio_event_id: confirm.add_on_seatsio_event_id});
                                  break;
                                case 'Seated Admission Chart':
                                  attrs = Object.assign(attrs, {seatsio_event_id: confirm.seatsio_event_id});
                                  break;
                              }

                              setNewCheckoutGroupItem({
                                ...newCheckoutGroupItem,
                                ...attrs
                              });
                            }
                          }
                          id="checkout-group-item-category">
                    <option value="AddOn">Add-On</option>
                    {confirm.donations_enabled ? (
                      <option value="Donation">Donation</option>
                    ) : null}
                    {confirm.add_on_seatsio_event_id ? (
                      <option value="Seated Add-on Chart">Seated Add-on Chart</option>
                    ) : null}
                    {confirm.seatsio_event_id ? (
                      <option value="Seated Admission Chart">Seated Admission Chart</option>
                    ) : null}
                    <option value="TicketType">Ticket Type</option>
                  </select>
                </div>
                {newCheckoutGroupItem.item_type === "TicketType" ? (
                  <div className="form-group">
                    <label className='mb-1'
                            htmlFor="checkout-group-item-ticket-type">
                      Ticket Type
                    </label>
                    <select className="form-control"
                            style={{
                              "borderRadius": "8px",
                              "borderWidth": "1px",
                              "fontSize": "14px"
                            }}
                            value={newCheckoutGroupItem.item_id || ""}
                            onChange={
                              (e) => {
                                setNewCheckoutGroupItem({
                                  ...newCheckoutGroupItem,
                                  item_id: e.target.value
                                });
                              }
                            }
                            id="checkout-group-item-ticket-type">
                      <option value="">Choose Ticket Type</option>
                      {ticketTypes.filter((tt) => !tt.seating_chart_category_key).sort((a, b) => a.name.localeCompare(b.name)).map((ticketType, index) =>
                        <option key={index} value={ticketType.id}>
                          {ticketType.name}
                        </option>
                      )}
                    </select>
                  </div>
                ) : newCheckoutGroupItem.item_type === "AddOn" ? (
                  <div className="form-group">
                    <label className='mb-1'
                            htmlFor="checkout-group-item-ticket-type">
                      Add-On
                    </label>
                    <select className="form-control"
                            style={{
                              "borderRadius": "8px",
                              "borderWidth": "1px",
                              "fontSize": "14px"
                            }}
                            value={newCheckoutGroupItem.item_id || ""}
                            onChange={
                              (e) => {
                                setNewCheckoutGroupItem({
                                  ...newCheckoutGroupItem,
                                  item_id: e.target.value
                                });
                              }
                            }
                            id="checkout-group-item-ticket-type">
                      <option value="">Choose Add-On</option>
                      {addOns.filter((ao) => !ao.seating_chart_category_key).sort((a, b) => a.name.localeCompare(b.name)).map((addOn, index) =>
                        <option key={index} value={addOn.id}>
                          {addOn.name}
                        </option>
                      )}
                    </select>
                  </div>
                ) : null}
                <div className='row'>
                  <div className='col-12 text-right'>
                    <button type="submit"
                            disabled={!validCheckoutGroupItem(newCheckoutGroupItem)}
                            className="btn btn-primary btn-sm">
                      Add
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Customize;
